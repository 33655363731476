<template>
    <div id="pdfs" class="contenu">
        <div class="">
            <div v-if="nouveauContexte && ready">
                <table id="listePdfs" class="tableSTD" v-if="lesPdfs && lesPdfs.length">
                    <tr class="itemPdf"  v-for="(lePdf, numPdf) in lesPdfs" :id="numPdf" :key="numPdf" v-bind:class="{ pair: Math.trunc(numPdf/2)*2==numPdf}"  >
                        <!-- <td class="libelle auto grand"> -->
                        <td class="libelle grand">
                            {{lePdf.titre}}
                            <span class="petit description" v-if="lePdf.description"><br/>{{lePdf.description}}</span>
                        </td>

                        <td class="lien ico" @click.stop="modifierPdf(lePdf)" title="Modifier le document">
                            <i class="fa fa-pencil svg" aria-hidden="true"></i>
                        </td>

                        <td v-if="lePdf.fichier" @click.stop="ouvrirPdf(lePdf)" class="ico lien" title="Télécharger le document">
                            <i class="fa fa-download svg" aria-hidden="true"></i>
                        </td>
                        <td v-if="lePdf.lien" class="ico lien" @click.stop="ouvrirLien(lePdf)" title="Ouvrir le lien">
                            <i class="fa fa-link svg" aria-hidden="true"></i>
                        </td>
                        <td class="lien ico poubelle" @click.stop="lePdfCourant=lePdf;confirmDialog=true" title="Supprimer le document">
                            <i class="fa fa-trash svg" aria-hidden="true"></i>
                        </td>
<!--
                        <td class="fin">
                            &nbsp;
                        </td>
-->
                    </tr>
                </table>

            </div>

        </div>

            <v-dialog v-model="confirmDialog" class="dechetterie">
                    <v-card class="dechetterie">
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous détruire ce document ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="abandonner">Non</v-btn>
                            <v-btn color="" text @click="detruire">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"


    export default {
        name: 'pdfs',
        components: {
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                leProjetId:0,
                laUcId:0,
                leSprintId:0,
                pdfs:null,
                ready:false,
                active:[],
                numActiveCourant:-1,
                confirmDialog:false,
                lePdfCourant:null,
                recharger:false
            }
        },


        watch: {
            recharger(newValue) {
                if (newValue) {
                    this.leProjetId = this.leContexte.projet.id
                    if (this.leContexte.sprint) {
                        this.leSprintId = this.leContexte.sprint.id;
                    }
                    if (this.leContexte.uc) {
                        this.laUcId = this.leContexte.uc.id;
                    }
                    if (this.leProjetId || this.laUcId || this.leSprintId) {
                        this.lirePdfs();
                    }
                }
            }

        },

        created: function () {

        },

        mounted: function () {
        },

        computed: {
            nouveauContexte() {
                this.recharger = true;
                return(this.leContexte);
            },
            lesPdfs() {
                return(this.pdfs);
            },
        },

        methods: {
            preparerLien(lePdf) {
                return(lePdf.lien.startsWith("http") ? lePdf.lien : "http://"+lePdf.lien);
                },
            ouvrirLien(lePdf) {
                var url = this.preparerLien(lePdf);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('target', '_blank'); //or any other extension
                document.body.appendChild(link);
                link.click();
            },
            ouvrirPdf(lePdf) {
                addeoGet.downloadDocument(lePdf).then(blob => {
                    if (blob) {
                        //const url = window.URL.createObjectURL(new Blob([response]));
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', lePdf.fichier); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                    else {
                        console.log("pb ouvrirPdf");
                    }
                })
            },
            modifierPdf(lePdf) {
                this.$emit('retourChoixPdf', lePdf);
            },

            abandonner() {
                this.confirmDialog = false;
            },
            detruire() {
                this.confirmDialog = false;
                this.supprimerPdf();
            },
            supprimerPdf() {
                var thisObj = this;
                addeoPost.supprimerDocument(this.lePdfCourant.id).then(response => {
                    thisObj.ready = false;
                    thisObj.recharger = false;
                    thisObj.$emit('retourSupprimerPdf', null);
                })
            },
            lirePdfs() {
                var thisObj = this;
                addeoGet.lireDocuments(thisObj.leProjetId, thisObj.laUcId, thisObj.leSprintId).then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.pdfs = response.retour.data;
                        thisObj.ready = true;
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    #app {
        #listePdfs {
            /*
            max-width: 1000px;
            min-width: 400px;
            width: 90%;
            */
            tr, td {
                position:relative;
            }
            td {
                padding:0.6em 1em;
                margin:0.4em 1em;

                &.auto {
                    white-space: nowrap;
                }

                &.ico {
                    width:1%;
                    max-width:30px;
                    /*
                    min-width:50px;
                    width:15%;
                    */
                }
                &.fin {
                    width:100%;
                }

                .v-select__slot {
                    background-color: transparent;
                    padding-top:0;

                    .v-select__selection {
                        color:#496785;
                    }
                    .v-icon {
                        color:#496785;
                    }
                }
                .v-text-field__details {
                    display:none;
                }

                .description {
                    opacity:0.7;
                }

            }
        }

    }



</style>
