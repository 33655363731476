<template>
    <div id="historique" v-bind:class="{front: !back, back: back}">
        <h4>
            <span class="sousTitre">
                <span class="">
                    Journal
                    <span v-if="blocVisible"> à
                        <v-text-field
                            class="addeo"
                            v-model="lesJours"
                            label="Jours"
                            ></v-text-field>
                    </span>

                    <i v-if="!blocVisible" class="fa fa-solid fa-caret-down lien" @click.stop="blocVisible=!blocVisible"></i>
                    <i v-if="blocVisible" class="fa fa-solid fa-caret-up lien" @click.stop="blocVisible=!blocVisible"></i>
                </span>
                <div class="radioGroupe aDroite" v-if="blocVisible">
                    <v-radio-group v-model="documentsVisibles"  v-on:change="parametrerJours">
                        <v-radio
                          label="Avancement"
                           :value=false
                        ></v-radio>
                        <v-radio
                          label="Documents"
                           :value=true
                        ></v-radio>
                  </v-radio-group>
                </div>
            </span>
        </h4>
        <div v-if="blocVisible">
            <div v-if="ready">
                <div v-if="!documentsVisibles && lHistorique && lHistorique.length">
                    <table class="tableSTD listeHistoriqueDocuments">

                        <tr class="itemListes"  v-for="(unHistorique, numHistorique) in lHistorique" :id="numHistorique" :key="numHistorique"   >
                            <td class="date" >
                                <span class="petit">{{unHistorique.dateAffichee}}</span>
                            </td>
                            <td class="structure droite" >
                                <span class="petit">{{unHistorique.projetUcSprint}}</span>
                            </td>
                            <td class=" grand" >
                                <span v-if="back && !unHistorique.sprint_id" class="lien" @click.stop="demanderNavigation(unHistorique)"><b>"{{unHistorique.libelle}}"</b></span>
                                <span v-if="!back || unHistorique.sprint_id"><b>"{{unHistorique.libelle}}"</b></span>
                            </td>
                            <td class="etat" >
                                <span class="petit">{{unHistorique.creationModification}} <b>"{{unHistorique.etat}}"</b></span>
                            </td>
                            <td class="petit" v-if="back">
                                <span class="checkbox">
                                    <v-checkbox
                                        v-model="unHistorique.visibilite"
                                        on-icon="fa fa-check-square"
                                        off-icon="fa fa-square"
                                        @change="majHistorique(unHistorique)"
                                    ></v-checkbox>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-if="documentsVisibles && lesDocuments && lesDocuments.length">
                    <table class="tableSTD listeHistoriqueDocuments">

                        <tr class="itemListes"  v-for="(unDocument, numDocument) in lesDocuments" :id="numDocument" :key="numDocument"   >
                            <td class="date" >
                                <span class="petit">{{unDocument.dateAffichee}}</span>
                            </td>
                            <td class="structure droite" >
                                <span class="petit">{{unDocument.projetUcSprint}}</span>
                            </td>
                            <td class=" moyenMoins" >
                                <span v-if="back" class="petit lien" @click.stop="demanderNavigation(unDocument)">"{{unDocument.libelle}}"</span>
                                <span v-if="!back" class="petit" >"{{unDocument.libelle}}"</span>
                            </td>
                            <td class="moyenPlus lien">
                                <span v-if="back" @click.stop="ouvrirDocument(unDocument)">{{unDocument.creationModification}}<b>"{{unDocument.titre}}"</b></span>
                                <span v-if="!back" @click.stop="ouvrirDocumentFront(unDocument)">{{unDocument.creationModification}}<b>"{{unDocument.titre}}"</b></span>
                            </td>
                            <td class="petit"  v-if="back">
                                <span class="checkbox">
                                    <v-checkbox
                                        v-model="unDocument.visibilite"
                                        on-icon="fa fa-check-square"
                                        off-icon="fa fa-square"
                                        @change="majDocument(unDocument)"
                                    ></v-checkbox>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>



    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../js/datas.js"

    export default {
        name: 'historique',
        components: {
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,
            back: Number,

        },

        data() {
            return {
                lesJours:"-1",
                lesJoursDefault:"30",
                blocVisible:false,
                documentsVisibles:true,
                laFamilleId:this.leContexte.famille.id,
                projets:null,
                ready0:false,
                ready:false,
                numActiveCourant:-1,
                confirmDialog:false,
                triEnCours:"",
                historiqueRecentAffichage:{
                    cpt:0,
                    liste:[]
                },
                documentsRecentsAffichage:{
                    cpt:0,
                    liste:[]
                }
            }
        },


        watch: {
            leContexte(newValue) {
                // pn ne passe jamais ICI - comprendre pourquoi (bug vue sur la mise à jour des objets) - fct placée dans created
                if (newValue) {
                    this.laFamilleId = this.leContexte.famille.id;
                    if (this.leContexte.lesJours) {
                        this.lesJours = this.leContexte.lesJours;
                    }
                }
            },

            ready0(newValue) {
                if (newValue) {
                    this.triEnCours = "";
                    this.ready = true;
                }
            },

            nbJours(newValue) {
                if (newValue >= 0) {
                    this.parametrerJours();
                }
            }
        },

        beforeCreate() {
         },

        created: function () {
            this.laFamilleId = this.leContexte.famille.id;
            if (this.leContexte.lesJours) {
                this.lesJours = this.leContexte.lesJours;
            }
            else  {
                this.lesJours = this.lesJoursDefault;
            }
            //this.parametrerJours();

        },

        mounted: function () {
        },

        computed: {
            nbJours() {
                var retour = parseInt(this.lesJours);
                if (isNaN(retour) || (retour<0)) {
                    retour = -1;
                }
                else {
                    this.leContexte.lesJours = this.lesJours;
                }
                return (retour);
            },
            lHistorique() {
                return(this.historiqueRecentAffichage.liste);
            },
            lesDocuments() {
                return(this.documentsRecentsAffichage.liste);
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.projets);
                }
                return(null);
            },
            lesEtatsSprints () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.sprints);
                }
                return(null);
            },
            /*
            laFamilleId() {
                if (this.leContexte) {
                    return(this.leContexte.famille.id);
                }
                return(0);
            }
            */
        },

        methods: {
// editerPDFs(leProjet)
            demanderNavigation(parentStructure) {
                this.$emit('retourJournal', parentStructure);
            },
            triDate(x, y) {
                if (x.datePasAffichee < y.datePasAffichee) {
                    return(1)
                }
                if (y.datePasAffichee < x.datePasAffichee) {
                    return(-1)
                }
                // donc si ==
                if (x.heurePasAffichee < y.heurePasAffichee) {
                    return(1)
                }
                if (y.heurePasAffichee < x.heurePasAffichee) {
                    return(-1)
                }
            },
            majHistorique(unHistorique) {
                addeoPost.majHistorique(unHistorique)
            },
            majDocument(unDocument) {
                addeoPost.majDocument(unDocument)
            },
            ouvrirDocument(document) {
                if (document.fichier) {
                    this.ouvrirPdf(document);
                }
                if (document.lien) {
                    this.ouvrirLien(document);
                }
            },
            ouvrirDocumentFront(document) {
                if (document.lien) {
                    this.ouvrirLien(document);
                }
                if (document.fichier) {
                    this.ouvrirFichier(document);
                }
            },
            ouvrirFichier(lePdf) {
                addeoGet.downloadDocument(lePdf).then(blob => {
                    if (blob) {
                        //const url = window.URL.createObjectURL(new Blob([response]));
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', lePdf.fichier); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                    else {
                        console.log("pb ouvrirFichier");
                    }
                })
            },

            preparerLien(lePdf) {
                return(lePdf.lien.startsWith("http") ? lePdf.lien : "http://"+lePdf.lien);
                },
            ouvrirLien(lePdf) {
                var url = this.preparerLien(lePdf);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('target', '_blank'); //or any other extension
                document.body.appendChild(link);
                link.click();
            },
            ouvrirPdf(lePdf) {
                addeoGet.downloadDocument(lePdf).then(blob => {
                    if (blob) {
                        //const url = window.URL.createObjectURL(new Blob([response]));
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', lePdf.fichier); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                    else {
                        console.log("pb ouvrirPdf");
                    }
                })
            },
            traiterDate(datePasAffichee) {
                var tab = datePasAffichee.split('-')
                var dateAffichee = tab[2]+"-"+tab[1]+"-"+tab[0];
                return(dateAffichee);

            },
            initialiser() {
                const thisObj = this;
                // traitement historique
                this.historiqueRecent.forEach((historique, index) => {
                    // création / modification
                    if ((historique.modified === historique.created) /*|| (historique.modified < historique.created)*/) {
                        historique.creationModification = "Etat ";
                        historique.datePasAffichee = historique.created.substring(0,10);
                        historique.dateAffichee = this.traiterDate(historique.datePasAffichee);
                        historique.heurePasAffichee = historique.created.substring(11,16);
                    }
                    else {
                        // on ne s'interesse qu'à la dernière - (possible en cas de correction de l'historique)
                        historique.creationModification = "Correction vers l'état ";
                        historique.datePasAffichee = historique.modified.substring(0,10);
                        historique.dateAffichee = this.traiterDate(historique.datePasAffichee);
                        historique.heurePasAffichee = historique.modified.substring(11,16);
                    }
                    // projet / uc / sprint
                    if (historique.projet_id) {
                        historique.projetUcSprint = "Projet";
                        historique.etat = thisObj.$parent.$parent.objEtats.projets[historique.const_etat_code].libelle;
                    }
                    if (historique.uc_id) {
                        historique.projetUcSprint = "UC";
                        historique.etat = thisObj.$parent.$parent.objEtats.ucs[historique.const_etat_code].libelle;
                    }
                    if (historique.sprint_id) {
                        historique.projetUcSprint = "Sprint";
                        historique.etat = thisObj.$parent.$parent.objEtats.sprints[historique.const_etat_code].libelle;
                    }
                });
                var historiqueRecent = [];
                historiqueRecent = historiqueRecent.concat(this.historiqueRecent.sort(this.triDate));
                this.historiqueRecentAffichage.cpt++;
                this.historiqueRecentAffichage.liste = historiqueRecent;


                // traitement documents
                this.documentsRecents.forEach((document, index) => {
                    var postIntro = "";
                    if (document.fichier) {
                        postIntro = "document ";
                    }
                    if (document.lien) {
                        postIntro = "lien ";
                    }

                    // création / modification
                    if (document.modified === document.created) {
                        document.creationModification = "Dépôt du "+postIntro;
                        document.datePasAffichee = document.created.substring(0,10);
                        document.dateAffichee = this.traiterDate(document.datePasAffichee);
                        document.heurePasAffichee = document.created.substring(11,16);
                    }
                    else {
                        // on ne s'interesse qu'à la dernière - (possible en cas de modification du document)
                        document.creationModification = "Mise à jour du "+postIntro;
                        document.datePasAffichee = document.modified.substring(0,10);
                        document.dateAffichee = this.traiterDate(document.datePasAffichee);
                        document.heurePasAffichee = document.modified.substring(11,16);
                    }
                    // projet / uc / sprint
                    if (document.projet_id) {
                        document.projetUcSprint = "Projet";
                    }
                    if (document.uc_id) {
                        document.projetUcSprint = "UC";
                    }
                    if (document.sprint_id) {
                        document.projetUcSprint = "Sprint";
                    }
                });
                var documentsRecents = [];
                documentsRecents = documentsRecents.concat(this.documentsRecents.sort(this.triDate));
                this.documentsRecentsAffichage.cpt++;
                this.documentsRecentsAffichage.liste = documentsRecents;

                this.ready0 = true;
            },
            parametrerJours() {
                const thisObj = this;

                const id = this.leContexte.famille.id;
                addeoPost.lireHistoriqueRecents(id, thisObj.nbJours, thisObj.back).then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.historiqueRecent = response.retour.data;
                        addeoPost.lireDocumentsRecents(id, thisObj.nbJours, thisObj.back).then(response => {
                            if (response && response.retour && response.retour.success == true) {
                                thisObj.documentsRecents = response.retour.data;
                                this.initialiser();
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    $couleur-hover: #0583c5;
    #app {
            .contenu{
            #historique {
                margin-bottom:2em;

                h4 {
                    .sousTitre {
                        /*background-color:#e9e9e9;*/
                    }
                    > span {
                        width:100%;
                        display: inline-block;
                        font-size: 1em;
                        padding:0.8em 1.4em;
                    }
                    i {
                        margin-left:0.6em;
                        margin-top:0.15em;
                        vertical-align: top;
                        font-size:1.6em;
                    }
                    margin-bottom:0.4em;

                    .radioGroupe {
                        display: inline-block;
                        div {
                            display: inline-block;
                        }
                        .v-input--selection-controls__input {
                            margin: 0 0.4em 0 1em;
                        }
                        .v-messages {
                            display: none;
                        }
                        .v-icon {
                            color:#004A71;
                        }
                        .v-input--selection-controls, .v-radio {
                            margin:0;
                            padding:0;
                        }
                        .v-radio {
                            label {
                                color:#004a71;
                                &:hover {
                                    color:$couleur-hover;
                                }
                            }
                        }
                    }

                    .addeo {
                        max-width: 44px;
                        display:inline-block;
                        padding: 0;
                        margin: 0;
                        .v-input__slot {
                            padding: 0.5em 0.2em 0;
                            margin:0;
                            .v-text-field__slot {
                                padding:0;
                                .v-label {
                                    font-size:14px;
                                    &.v-label--active {
                                        transform: translateY(-18px) scale(0.75);
                                    }
                                }
                                input {
                                    padding:0.2em 0;
                                    line-height: 0.8em;
                                    text-align: center;
                                }
                            }
                        }
                        .v-text-field__details {
                            display:none;
                        }
                    }
                }

                .listeHistoriqueDocuments {
                    width: 100%;
                    color:#666;

                    tr, td {
                        position:relative;
                    }
                    tr {

                        td, th {
                            padding: 0.2em 0.5em;
                            /*background-color:#e9e9e9;*/

                            .v-select__slot {
                                background-color: transparent;
                                padding-top:0;

                                .v-select__selection {
                                    color:#496785;
                                }
                                .v-icon {
                                    color:#496785;
                                }
                            }
                            .v-text-field__details {
                                display:none;
                            }
                            &.petit {
                                font-size: 0.8em;
                            }
                            .v-input--checkbox {
                                .v-input--selection-controls__ripple {
                                    border-radius: 50%;
                                    cursor: pointer;
                                    height: 30px;
                                    position: absolute;
                                    transition: inherit;
                                    width: 30px;
                                    left: -12px;
                                    top: calc(50% - 20px);
                                    margin: 7px;
                                }
                            }
                            &.grand {
                                span {
                                    max-width: 605px;
                                    display: inline-block;
                                }
                            }
                            &.date {
                                span {
                                    min-width: 74px;
                                    display: inline-block;
                                }
                            }
                            &.structure {
                                span {
                                    /*min-width: 50px;*/
                                    display: inline-block;
                                }
                            }
                            &.etat {
                                span {
                                    min-width: 265px;
                                    display: inline-block;
                                }
                            }
                            &.moyenMoins {
                                span {
                                    min-width: 330px;
                                    display: inline-block;
                                }
                            }
                            &.moyenPlus {
                                span {
                                    min-width: 550px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
                &.front {
                    .listeHistoriqueDocuments {
                        tr {
                            td, th {
                                &.grand {
                                    span {
                                        max-width: 660px;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }



</style>
