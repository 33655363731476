<template>
    <div id="contexte" class="contenu">
        <img alt="Vue logo" class="logo aGauche" src="../../../public/img/addeo.png">
        <div class="infosContexte aGauche" v-if="ready">
            <h1>{{client.libelle}}</h1>
            <h2>{{famille.libelle}}</h2>
        </div>
        <div class="aDroite" >
            <h6>Informations</h6>
            <a href="/datas/LEXIQUE-METHODE.pdf" target="_blank" alt="Lexique" title="Lexique"><i class="fa fa-arrow-right svg clair" aria-hidden="true"></i>&nbsp;Lexique</a>
            <a href="/datas/PRES-METHODE-V1.pdf" target="_blank" alt="Méthode" title="Méthode"><i class="fa fa-arrow-right svg clair" aria-hidden="true"></i>&nbsp;Méthode</a>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>

    const codeCLIENT = "GRAND-EST";
    const codeFAMILLE = "ORIENTEST";

    import { addeoPost, addeoGet } from "../../js/datas.js"


    export default {
        name: 'contexte',
        components: {
        },

        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                client:null,
                famille:null,
                ready:false
            }
        },


        created: function () {

            let thisObj = this;
            addeoGet.lireClients().then(response => {
                if (response && response.success == true) {
                    let clients = response.data;
                    clients.forEach((client, index) => {
                        clients[client.code] = client;
                    })
                    thisObj.client = clients[codeCLIENT];

                    addeoGet.lireFamilles().then(response => {
                        if (response && response.success == true) {
                            let familles = response.data;
                            familles.forEach((famille, index) => {
                                familles[famille.code] = famille;
                            })
                            thisObj.famille = familles[codeFAMILLE];

                            // retour
                            var monContexte = new Object();
                            monContexte.client =this.client;
                            monContexte.famille = this.famille;
                            monContexte.projet = null;
                            monContexte.uc = null;
                            monContexte.sprint = null;
                            thisObj.ready = true;
                            this.$emit('retourContexte', monContexte);
                        }
                    })

                }
            })

        },

    }
</script>
<style lang="scss">
$couleur-texte: #496785;
$couleur-lien: #004a71;
$couleur-hover: #0583c5;
    #app {
        #contexte {
            background-color: #F2F2F2;
            padding-bottom: 1em;

            img.logo {
                max-width: 200px;
                height: auto;
                margin:1em 0;
            }
            .infosContexte {
                margin-left:2em;
                border-left: 8px #0075BE solid;
                padding-left: 1em;
                h1 {
                    color:#0075BE;
                }
                h2 {
                    color:$couleur-texte;
                    padding:0;
                    margin:0.4em 0 0;
                    background-color: transparent;
                }
            }
            a {
                color:$couleur-lien;
                text-align: right;
                display:block;
                text-decoration: none;
            }
        }
    }

</style>
