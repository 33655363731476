<template>
    <div id="editerSprint" class="contenu">
        <div v-if="!nouveauSprint" class="sousTitre">
            <h4 class="aGauche">
                Sprints de l'UC : {{laUc.code}}
                <br/>
                <span>{{laUc.libelle}}</span>
            </h4>
            <div class="aDroite">
                <v-btn  class="a-btn prioritaire" type="button" @click="nouveauSprint=true"><i class="fa fa-plus svg" aria-hidden="true"></i>&nbsp;{{libelleCheckBox}}</v-btn>
            </div>
            <div class="clear"></div>
        </div>
<!--
        <span class="checkbox">
            <v-checkbox
                v-model="nouveauSprint"
                :label="libelleCheckBox"
                on-icon="fa fa-check-square"
                off-icon="fa fa-square"
            ></v-checkbox>
        </span>
-->
        <div v-if="nouveauSprint">
                <div class="edition">
                    <p class="info">Les champs suivis d'une * sont obligatoires.</p>
                    <v-form v-if="ready">

                        <v-select
                            dense
                            v-model="form.phase_code"
                            :error-messages="error.phase_code"
                            item-text="libelle"
                            item-value="code"
                            :items="lesPhases"
                            label="Indiquer la phase du Sprint *"
                            required
                            ref="phaseCode"
                            @input="traiterPhase"
                        ></v-select>

                        <div id="prefixe" v-if="prefixe">
                            {{prefixe}}
                        </div>

                        <v-text-field
                                v-model="form.code"
                                :counter="20"
                                :error-messages="error.code"
                                label="Code du Sprint (préfixe automatique) : *"
                                required
                                @input="$v.form.code.$touch()"
                                @blur="$v.form.code.$touch()"
                                ref="code"
                        ></v-text-field>
                        <v-text-field
                                v-model="form.libelle"
                                :error-messages="error.libelle"
                                label="Nom du Sprint : *"
                                required
                                @input="$v.form.libelle.$touch()"
                                @blur="$v.form.libelle.$touch()"
                                ref="libelle"
                        ></v-text-field>

                            <v-btn v-if="form.deadline!=''" @click.stop="form.deadline=''" class="aDroite pourDeadline" type="button">Effacer<br/>la Deadline</v-btn>
                        <v-menu
                            v-model="lePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="form.deadline"
                                    label="Deadline"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ref="deadline"
                                  >
                                </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="form.deadline"
                              @input="lePicker = false"
                            locale="fr"
                            >
                            </v-date-picker>
                        </v-menu>


                        <v-textarea
                                v-model="form.remarques"
                                label="Remarques / description :"
                        ></v-textarea>

                        <v-select
                            dense
                            v-model="form.etatCode"
                            :error-messages="error.etatCode"
                            item-text="libelle"
                            item-value="code"
                            :items="lesEtats"
                            label="Indiquer l'état du Sprint *"
                            required
                            ref="etatCode"
                        ></v-select>



                        <div class="boutonsValidation">
                            <v-btn   class="a-btn prioritaire" type="button" @click="demanderMaj">Valider</v-btn>
                            <v-btn   class="a-btn " type="button" @click="demanderAbandonnerMaj">Abandonner</v-btn>
                        </div>

                    </v-form>


                </div>



            <v-dialog v-model="dialogError" v-if="errorDemandeMaj" @click:outside="fermerDialogError()" >
                 <v-card>
                     <v-card-title class="headline">Enregistrement du sprint</v-card-title>
                     <v-card-text>
                         <span v-if="errorDemandeMaj">{{errorDemandeMaj}}</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="confirmDialog">
                    <v-card>
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous abandonner votre saisie d'un sprint ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="reprendreSaisie">Non</v-btn>
                            <v-btn color="" text @click="abandonnerSaisie">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
        </div>

    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
/*
    import { ApiTools } from "../../js/api/index.js"
*/
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'

    //import MenuIcon from 'vue-material-design-icons/Menu.vue';

    //import 'vue-material-design-icons/styles.css';

    export default {
        mixins: [validationMixin],
        name: 'sprintForm',
        components: {
            //MenuIcon
        },
        validations: {
            form: {
                code: { required, maxLength: maxLength(20)},
                libelle: { required, maxLength: maxLength(256) },
                remarques: {   },
                etatCode: {  required },
                phase_code: {  required },
            }
        },
        props: {
        /**
         * Données à injecter
         */
            leSprint: Object,
            laUc: Object
        },

        data() {
            return {
                ucId:this.laUc.id,
                nouveauSprint:false,
                form: {
                    id:0,
                    code: "",
                    libelle: "",
                    remarques:"",
                    uc_id:0,
                    publie:0,
                    etatCode:"",
                    phase_code:"",
                    deadline: "",
                },
                old: null,
                error: {
                    code: "",
                    libelle: "",
                    remarques:"",
                    etatCode:"",
                    phase_code:"",
                },
                prefixe:"",
                confirmDialog:false,
                dialogError:false,
                errorDemandeMaj:false,
                messageMaj:"",
                messageSucces:"Le sprint est enregistré !",
                ready:false,
                lePicker: false,
                nowDate: new Date().toISOString().slice(0,10),
            }
        },


        watch: {
            leSprint(newValue) {
                if (newValue) {
                    this.form.id = this.leSprint.id;
                    this.form.code = this.leSprint.code.substr(2);
                    this.form.libelle = this.leSprint.libelle;
                    this.form.remarques = this.leSprint.remarques;
                    if (this.leSprint.historique && this.leSprint.historique.length) {
                        this.form.etatCode = this.leSprint.historique[0].const_etat_code;
                    }
                    this.form.phase_code = this.leSprint.phase_code;
                    this.prefixe = "S"+this.leSprint.phase_code;
                    this.form.uc_id = this.leSprint.uc_id;
                    this.form.deadline = this.leSprint.deadline;
                    this.form.publie = this.leSprint.publie;
                    this.old = JSON.parse(JSON.stringify(this.form));
                    this.nouveauSprint = true;
                }
            },
            nouveauSprint(newValue) {
                if (!newValue) {
                    this.initForm();
                    this.$emit('editionEnCours', false);
                }
                else {
                    this.$emit('editionEnCours', true);
                }
            },
            ready(newValue) {
                if (newValue) {
                    this.initForm();
                }
            }
        },

        created: function () {
            if ((this.lesEtats) && (this.lesPhases)) {
                this.ready = true;
            }

        },

        mounted: function () {
        },

        computed: {
            libelleCheckBox() {
                if (this.form.id) {
                    return('Modification de "' +this.form.libelle+'"');
                }
                return("Nouveau sprint");
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    var info = new Object();
                    info.libelle = "Indiquez un état";
                    info.code = "";
                    var tab = this.$parent.$parent.objEtats.sprints.slice();
                    tab.unshift(info);
                    return(tab);
                }
                return(null);
            },
            lesPhases () {
                if (this.$parent.$parent.tabPhases) {
                    var info = new Object();
                    info.libelle = "Indiquez une phase";
                    info.code = "";
                    var tab = this.$parent.$parent.tabPhases.slice();
                    tab.unshift(info);
                    return(tab);
                }
                return(null);
            },
            codeErrors () {
                if (!this.$v.form.code.$invalid) return false // verifier invalid
                if (!this.$v.form.code.required) {
                     this.error.code  = "Vous devez saisir un code unique pour le sprint"
                }
                if  ((!this.$v.form.code.maxLength)) {
                     this.error.code  = "La taille limite d'un code est de 22 caractères avec son préfixe";
                }
                this.$refs.code.focus();
                return true
            },
            libelleErrors () {
                if (!this.$v.form.libelle.$invalid) return false // verifier invalid
                if (!this.$v.form.libelle.required) {
                     this.error.libelle  = "Vous devez saisir un nom pour le sprint"
                }
                this.$refs.libelle.focus();
                return true
            },
            remarquesErrors () {
                if (!this.$v.form.remarques.$invalid) return false // verifier invalid
                /*
                if (!this.$v.form.remarques.required) {
                     this.error.remarques  = "Vous devez saisir votre remarques"
                }
                */
                this.$refs.remarques.focus();
                return true
            },
            etatCodeErrors () {
                if (!this.$v.form.etatCode.$invalid) return false // verifier invalid
                if (!this.$v.form.etatCode.required) {
                     this.error.etatCode  = "Vous devez indiquer un état"
                }
                this.$refs.etatCode.focus();
                return true
            },
            phaseCodeErrors () {
                if (!this.$v.form.phase_code.$invalid) return false // verifier invalid
                if (!this.$v.form.phase_code.required) {
                     this.error.phase_code  = "Vous devez indiquer une phase"
                }
                this.$refs.phaseCode.focus();
                return true
            },
            existeCodeSprint() {
                if (!this.form.code.length){
                    return(false);
                }
                if (this.$parent.projetListeSprints && this.$parent.projetListeSprints[this.form.code]) {
                    return(true);
                }
                return(false);
            }
        },
        methods: {
            traiterPhase() {
                this.prefixe = "S"+this.form.phase_code;
            },
            initForm() {
                this.form.id = 0;
                this.form.code = "";
                this.form.libelle = "";
                this.form.remarques = "";
                this.form.etatCode = this.lesEtats[1].const_etat_code;
                this.form.phase_code = this.lesPhases[0].code;
                this.form.uc_id = this.ucId;
                this.form.publie = 1;
                this.reinitErrorMessages();

            },
            reinitErrorMessages() {
                this.error.code = "";
                this.error.libelle = "";
                this.error.remarques = "";
                this.error.etatCode = "";
                this.error.phase_code = "";
            },
            abandonnerSaisie() {
                this.confirmDialog=false;
                this.nouveauSprint=false;
                //this.$emit('retourProjetPdf', null);
            },
            reprendreSaisie() {
                this.confirmDialog=false;
            },
            demanderAbandonnerMaj() {
                this.confirmDialog=true;
            },
            demanderMaj() {
                this.reinitErrorMessages();
                this.$v.$touch();
                var codeErrors = this.codeErrors;
                var libelleErrors = this.libelleErrors;
                var remarquesErrors = this.remarquesErrors;
                var etatCodeErrors = this.etatCodeErrors;
                var phaseCodeErrors = this.phaseCodeErrors;
                if ( (this.$v.$invalid &&
                      (codeErrors || libelleErrors || remarquesErrors || etatCodeErrors  || phaseCodeErrors)) ) {

                    console.log("submit pas OK");
                    return;
                }

                // si pas de modification des données
                if (this.old && (JSON.stringify(this.old) === JSON.stringify(this.form))) {
                    this.errorDemandeMaj = "Le sprint est inchangé, inutile de l'enregistrer !";
                    this.dialogError = true;
                    return
                }

                const tmp = this.form.code;
                this.form.code = this.prefixe + this.form.code;
                if (this.nouveauSprint && !this.form.id && this.existeCodeSprint) {
                    this.form.code = tmp;
                    this.errorDemandeMaj = "Le code que vous avez saisi pour le sprint existe déjà. Vous devez le modifier.";
                    this.dialogError = true;
                    return
                    }
                var thisObj = this;
                addeoPost.majSprint(this.form)
                .then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.errorDemandeMaj = thisObj.messageSucces;
                        thisObj.dialogError = true;
                        thisObj.$emit('retourSprint', response.retour.data);
                    }
                    else {
                        thisObj.form.code = tmp;
                        thisObj.dialogError = true;
                        var complementErreur = "merci de nous contacter";
                        if (response && response.erreur) {
                            complementErreur = response.erreur;
                        }
                        thisObj.errorDemandeMaj = "Enregistrement impossible : " + complementErreur;
                        console.log("demanderMaj non valide")
                    }
                })
            },

            fermerDialogError(data) {
                this.dialogError = false;

                if (this.errorDemandeMaj) {
                    if (this.errorDemandeMaj === this.messageSucces) {
                        this.nouveauSprint = false;
                        this.initForm();
                    }
                    else {
                        this.$nextTick(() => {
                            this.$refs.code.focus();
                        })
                    }
                }
                this.errorDemandeMaj = false;
            },
            // pour la saisie
            /*
            filtrerNombresEntiersPositifs(e){
                return(ApiTools.filtrerNombresEntiersPositifs(e))
            },
            */
        }
    }
</script>

<style lang="scss">
    #app {
        #editerSprint {
            .edition {
                margin:1em 0 0 4em;
            }
            #prefixe {
                float:left;
                margin:19px 0 2px;
                padding:20px 0.4em 15px;
                background-color: #8095AA;
                border-radius: 5px 5px 0 0;
                color:white;
                font-weight: bold;
                /*border-bottom: 15px solid #EDEDED;*/
            }
            .pourDeadline {
                margin-top:24px;
                margin-left:2px;
                /*padding-top: 16px;
                padding-bottom: 16px;*/
                height:46px;
            }

            .v-messages__message {
                color:darkorange;
            }

        }
        .v-picker__title.primary {
            background-color: #1867c0 !important;
            border-color: #1867c0 !important;
            max-height:10px;
            div {
                display:none ;
            }
        }
            .v-date-picker-title {
                color:white;
            }
    }



</style>
