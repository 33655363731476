<template>
  <div class="container" id="back">

    <Contexte :leContexte="contexte" v-if="1 || (!contexte || (!contexte.editionEnCours && !contexte.projet))" @retourContexte="onRetourContexte" />

    <div class="contenu" v-if="ready">

        <div v-if="ready2Go && readyProjet">
            <Historique v-if="!contexte.editionEnCours" :leContexte="contexte" :back=1 @retourJournal="onRetourJournal" />
        </div>

        <div v-if="readyProjet">

            <ProjetForm :leProjet="projetEdite" :familleId="contexte.famille.id" @retourProjet="onRetourProjet" @editionEnCours="onEditionEnCours"/>

            <Projets v-if="!contexte.editionEnCours" :leContexte="contexte"  @retourChoixProjet="onRetourChoixProjet" @retourEditerPDFsProjet="onRetourEditerPDFsProjet" @retourChargerUCs="onRetourChargerUCs" @retourDepublierProjet="onRetourDepublierProjet" @retourModifierHistorique="onRetourModifierHistorique" />

        </div>

        <div v-if="readyProjetPdf">
            <div @click.stop="onRetourProjets" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des projets</div>
            <ProjetPdfForm :lePdf="pdfEdite" :leContexte="contexte" @retourProjetPdf="onRetourProjetPdf" @editionEnCours="onEditionEnCours"/>
            <Pdfs v-if="!contexte.editionEnCours" :leContexte="contexte"  @retourChoixPdf="onRetourChoixPdf" @retourSupprimerPdf="onRetourSupprimerPdf" />
        </div>
          <div v-if="readyUcPdf">
            <div v-if="!contexte.editionEnCours" @click.stop="onRetourProjets" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des projets</div>
            <div v-if="!contexte.editionEnCours" @click.stop="onRetourUcs" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des UCs</div>
            <ProjetPdfForm :lePdf="pdfEdite" :leContexte="contexte" @retourProjetPdf="onRetourProjetPdf" @editionEnCours="onEditionEnCours"/>
            <Pdfs v-if="!contexte.editionEnCours" :leContexte="contexte"  @retourChoixPdf="onRetourChoixPdf" @retourSupprimerPdf="onRetourSupprimerPdf" />
        </div>
          <div v-if="readySprintPdf">
            <div v-if="!contexte.editionEnCours" @click.stop="onRetourProjets" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des projets</div>
            <div v-if="!contexte.editionEnCours" @click.stop="onRetourUcs" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des UCs</div>
            <div v-if="!contexte.editionEnCours" @click.stop="onRetourSprints" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des sprints</div>
            <ProjetPdfForm :lePdf="pdfEdite" :leContexte="contexte" @retourProjetPdf="onRetourProjetPdf" @editionEnCours="onEditionEnCours"/>
            <Pdfs v-if="!contexte.editionEnCours" :leContexte="contexte"  @retourChoixPdf="onRetourChoixPdf" @retourSupprimerPdf="onRetourSupprimerPdf" />
        </div>


        <div v-if="readyUc">
            <div v-if="!contexte.editionEnCours" @click.stop="onRetourProjets" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des projets</div>

            <UcForm :laUc="ucEdite" :leProjet="contexte.projet" @retourUc="onRetourUc" @editionEnCours="onEditionEnCours"/>

            <Ucs v-if="!contexte.editionEnCours" :leContexte="contexte"  @retourChoixUc="onRetourChoixUc" @retourEditerPDFsUc="onRetourEditerPDFsUc" @retourChargerSprints="onRetourChargerSprints" @retourDepublierUc="onRetourDepublierUc" @retourModifierHistorique="onRetourModifierHistorique" />

        </div>

        <div v-if="readySprint">
            <div v-if="!contexte.editionEnCours" @click.stop="onRetourUcs" class="lien gauche"><i class="fa fa-arrow-left avantTexte" aria-hidden="true"></i>Retour à la liste des UCs</div>

            <SprintForm :leSprint="sprintEdite" :laUc="contexte.uc" @retourSprint="onRetourSprint" @editionEnCours="onEditionEnCours"/>

            <Sprints v-if="!contexte.editionEnCours" :leContexte="contexte"  @retourChoixSprint="onRetourChoixSprint" @retourEditerPDFsSprint="onRetourEditerPDFsSprint" @retourDepublierSprint="onRetourDepublierSprint" @retourModifierHistorique="onRetourModifierHistorique"/>
        </div>

        <div v-if="readyHistorique">
            <HistoriqueForm :leContexte="contexte"  @retourHistorique="onRetourHistorique" />
        </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Contexte from '@/components/back/Contexte.vue'
import ProjetForm from '@/components/back/ProjetForm.vue'
import Projets from '@/components/back/Projets.vue'
import ProjetPdfForm from '@/components/back/ProjetPdfForm.vue'
import Pdfs from '@/components/back/Pdfs.vue'
import UcForm from '@/components/back/UcForm.vue'
import Ucs from '@/components/back/Ucs.vue'
import SprintForm from '@/components/back/SprintForm.vue'
import Sprints from '@/components/back/Sprints.vue'
import HistoriqueForm from '@/components/back/HistoriqueForm.vue'
import Historique from '@/components/Historique.vue'

import { addeoPost, addeoGet } from "../js/datas.js"

export default {
      name: 'Back',
      components: {
        Contexte,
        ProjetForm,
        Projets,
        ProjetPdfForm,
        Pdfs,
        UcForm,
        Ucs,
        SprintForm,
        Sprints,
        HistoriqueForm,
        Historique
      },

    data() {
        return {
            contexte:null,
            projetEdite:null,
            pdfEdite:null,
            ucEdite:null,
            sprintEdite:null,
            ready:false,
            readyContexte:false
        }
    },

    created: function () {
        console.log("Back : created");
    },

    watch: {
            ready2Go(newValue) {
                this.ready = newValue;
            }
        },

    computed: {
        ready2Go() {
            return(this.$parent.ready && this.readyContexte);
        },
        /*
        ready() {
            return(this.$parent.ready);
        },
        */
        readyProjet() {
            return(this.ready && this.contexte && !this.contexte.projet);
        },
        readyProjetPdf() {
            return(this.ready && this.contexte && this.contexte.projet && !this.contexte.uc && this.contexte.editerPDFsProjet); // valider la necessité de l'ajout de la remarque
        },
        readyUcPdf() {
            return(this.ready && this.contexte && this.contexte.projet && this.contexte.uc && !this.contexte.sprint  && this.contexte.editerPDFsProjet); // valider la necessité de l'ajout de la remarque
        },
        readySprintPdf() {
            return(this.ready && this.contexte && this.contexte.projet && this.contexte.uc && this.contexte.sprint && this.contexte.editerPDFsProjet); // valider la necessité de l'ajout de la remarque
        },
        readyUc() {
            return(this.ready && this.contexte && this.contexte.projet && !this.contexte.uc && !this.contexte.editerPDFsProjet);
        },
        readySprint() {
            return(this.ready && this.contexte && this.contexte.projet && this.contexte.uc && !this.contexte.sprint && !this.contexte.editerPDFsProjet);
        },
        readyHistorique() {
            return(this.ready && this.contexte && this.contexte.historique);
        },
    },

    methods: {
        reinitialiserContexte(editerPDFs=false) {
            this.contexte.editerPDFsProjet = editerPDFs;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onEditionEnCours(editionEnCours) {
            this.contexte.editionEnCours = editionEnCours;
            this.reinitialiserContexte(this.contexte.editerPDFsProjet);
        },
        onRetourContexte(contexte) {
            this.contexte = contexte;
            this.reinitialiserContexte();
            this.readyContexte = true;
        },
        onRetourJournal(parent) {
            if (parent.const_etat_code) {
                if (parent.projet_id) {
                    this.onRetourChargerUCs(parent.structure);
                }
                if (parent.uc_id) {
                    this.onRetourChargerSprints(parent.structure);
                }
            }
            else {
                if (parent.projet_id) {
                    this.onRetourEditerPDFsProjet(parent.structure);
                }
                if (parent.uc_id) {
                    this.onRetourEditerPDFsUc(parent.structure);
                }
            }
        },
        onRetourChoixProjet(projet) {
            this.projetEdite = JSON.parse(JSON.stringify(projet));
        },
        onRetourDepublierProjet(projet) {
            this.contexte.projet = null;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onRetourChargerUCs(projet) {
            this.contexte.projet = JSON.parse(JSON.stringify(projet));
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        traiterRetourSTD(projet, uc, sprint) {
            if (projet || uc || sprint) {
                if (projet) {
                    this.contexte.projet = JSON.parse(JSON.stringify(projet));
                }
                if (uc) {
                    this.contexte.uc = JSON.parse(JSON.stringify(uc));
                }
                if (sprint) {
                    this.contexte.sprint = JSON.parse(JSON.stringify(sprint));
                }
                this.reinitialiserContexte(true);
            }
            else {
                this.contexte.projet = null;
                this.contexte.uc = null;
                this.contexte.sprint = null;
                this.reinitialiserContexte();
            }
        },
        onRetourChoixPdf(pdf) {
            this.pdfEdite = JSON.parse(JSON.stringify(pdf));
        },
        onRetourSupprimerPdf(pdf) {
            this.reinitialiserContexte(true);
        },
        onRetourProjetPdf(pdf) {
            this.pdfEdite = null;
            this.reinitialiserContexte(true);
        },
        onRetourEditerPDFsProjet(projet) {
            this.traiterRetourSTD(projet, null, null);
        },
        onRetourEditerPDFsUc(uc) {
            if (!this.contexte.projet) {
                this.contexte.projet = this.contextualiserProjet(uc); // retrouver le projet !!!!
                }
            this.traiterRetourSTD(null, uc, null);
        },
        onRetourEditerPDFsSprint(sprint) {
            this.traiterRetourSTD(null, null, sprint);
        },
        onRetourProjets(data) {
            this.contexte.projet=null;
            this.contexte.uc=null;
            this.contexte.sprint=null;
            this.contexte.editionEnCours = false;
            this.reinitialiserContexte();
        },
        onRetourProjet(data) {
            /* inutile si on recharge le composant projets
            var projet = data.projet;
            var itemHistorique = data.historique;
            */
            // pour recharger le composant Projets
            this.reinitialiserContexte();
        },
        onRetourUcs(data) {
            this.contexte.uc=null;
            this.contexte.sprint=null;
            this.contexte.editionEnCours = false;
            this.reinitialiserContexte();
        },
        onRetourUc(data) {
            // pour recharger le composant Uc
            this.reinitialiserContexte();
        },
        onRetourChoixUc(laUc) {
            this.ucEdite = JSON.parse(JSON.stringify(laUc));
        },
        onRetourDepublierUc(laUc) {
            this.contexte.uc = null;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onRetourChargerSprints(uc) {
            this.contexte.uc = JSON.parse(JSON.stringify(uc));
            if (!this.contexte.projet) {
                this.contexte.projet = this.contextualiserProjet(uc); // retrouver le projet !!!!
                }
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onRetourSprints(data) {
            this.contexte.sprint=null;
            this.contexte.editionEnCours = false;
            this.reinitialiserContexte();
        },
        onRetourSprint(data) {
            // pour recharger le composant Uc
            this.reinitialiserContexte();
        },
        onRetourChoixSprint(leSprint) {
            this.sprintEdite = JSON.parse(JSON.stringify(leSprint));
        },
        onRetourDepublierSprint(leSprint) {
            this.contexte.sprint = null;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onRetourModifierHistorique(leHistorique) {
            this.contexte.historique = leHistorique;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onRetourHistorique(bidon) {
            this.contexte.historique = null;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },

            analyserRetardProjet(projet, dateDuJour, thisParam) {
                this.projetListeSprints = [];
                thisParam.totalUcs += projet.nbUcs;
                var totalUcs = 0;
                var thisObj = this;
                addeoGet.lireUcs(projet.id).then(response => {
                    thisParam.projetsTraitables++;
                    projet.enRetard = false;
                    if (response && response.retour && response.retour.success == true) {
                        var ucs = response.retour.data;
                        totalUcs += ucs.length;
                        ucs.forEach((uc, indexUc) => {
                            thisObj.analyserRetardUC(uc, dateDuJour, thisParam, projet);
                        })
                    }
                })
            },

            analyserRetardUC(uc, dateDuJour, thisParam, projet = null) {
                if (!projet) {
                    var totalUcs = thisParam.ucs.length;
                }
                var thisObj = this;
                addeoGet.lireSprints(uc.id).then(response => {
                    uc.enRetard = false;
                    if (response && response.retour && response.retour.success == true) {
                        var sprints = response.retour.data;

                        // libelle états des historiques
                        sprints.forEach((sprint, indexSprint) => {
                            thisObj.projetListeSprints[sprint.code] = 1;
                            if (sprint.historique && sprint.historique.length) {
                                const lEtat = thisParam.lesEtatsSprints[sprint.historique[0].const_etat_code];
                                if (!lEtat.fini && sprint.deadline) {
                                    // si pas fini, on compare Deadline et date du jour
                                    //if (dateDuJour > sprint.deadline) {
                                    if (thisObj.diffJours(dateDuJour, sprint.deadline) < 0) {
                                        uc.enRetard = 1;
                                    }
                                    if ((thisObj.diffJours(dateDuJour, sprint.deadline) < 7) && uc.enRetard <= 0) {
                                        uc.enRetard = -1;
                                    }
                                }
                                else {
                                    if (lEtat.code == "SARES" && !uc.enRetard) { // si quelquechose, on ne change pas !
                                        uc.enRetard = -2; // a restituer en vert !!!
                                    }
                                }
                            }
                        })
                        if (projet) {
                            if (uc.enRetard == 1) {
                                projet.enRetard = 1;
                            }
                            if ((uc.enRetard == -1) && projet.enRetard <= 0) {
                                projet.enRetard = -1;
                            }
                            if ((uc.enRetard == -2) && !projet.enRetard) {
                                projet.enRetard = -2;
                            }
                        }
                    }
                    thisParam.ucsTraiteesSprints ++;
                    if (projet) {
                        if (thisParam.totalProjets == thisParam.projetsTraitables) {
                            //console.log("ucsTraiteesSprints : ", thisParam.ucsTraiteesSprints);
                            if (thisParam.ucsTraiteesSprints == thisParam.totalUcs) {
                                thisParam.ready0 = true;
                            }
                        }
                    }
                    else {
                        if (thisParam.ucsTraiteesSprints == totalUcs) {
                            thisParam.ready0 = true;
                        }
                    }
                })
            },
            diffJours(chaineDate1, chaineDate2) {
                const tabTmp = chaineDate1.split('-');
                if (tabTmp[1].length == 1) {
                    tabTmp[1] = "0"+tabTmp[1];
                }
                if (tabTmp[2].length == 1) {
                    tabTmp[2] = "0"+tabTmp[2];
                }
                const chaineDate1Ok = tabTmp[0]+"-"+tabTmp[1]+"-"+tabTmp[2];
                const date1 = new Date(chaineDate1Ok);
                const date2 = new Date(chaineDate2);
                const Diff_temps = date2.getTime() - date1.getTime();
                var Diff_jours = Diff_temps / (1000 * 3600 * 24);
                return(Diff_jours);
            },
            contextualiserProjet(uc) {
                const thisObj = this;
                var contexteProjet = null;
                this.contexte.projets.forEach((projet, index) => {
                    if (uc.projet_id == projet.id) {
                        contexteProjet = projet;
                    }
                })
                return(contexteProjet);
            }
    }
}

</script>

<style lang="scss">

$couleur-fond: #496785;
$couleur-fondDivs: #F2F2F2;
$couleur-texte: #496785;
/*$couleur-lien: #6588AB;*/
$couleur-lien: #004A71;
$couleur-hover: #05621e;

    #app {
        .v-dialog {
            max-width: 600px;
        }

        .avantTexte {
            margin-right:1em;
            font-size:1.4em;
        }

        .container {
            max-width: 1200px;
            text-align: center;
            .contenu {
                /*padding:2em 2em 3em;*/
                padding:2em 2em 0;
                max-width: 1200px;
                margin: 0 auto;
                text-align: left;
                .edition {
                    width: 100%;
                    max-width: 405px;
                }

                .aGauche {
                    float:left;
                }
                .clear {
                    clear:both;
                }

            }
            H2 {
                text-align: left;
                margin:2em 0 1em;
                color:white;
                background-color: $couleur-fond;
                padding : 0.4em 1em;
            }
            H3 {
                text-align: left;
                margin:2em 0 1em;
                color:white;
                background-color: $couleur-fond;
                padding : 0.4em 1em;
                opacity: 0.7;
            }
            .lien {
                cursor:pointer;
                color:$couleur-lien;
                &:hover {
                    color:$couleur-hover;
                }
            }
            .toutPetit {
                font-size: 70%;
                margin-left: 1px;
            }
            .petit {
                font-size: 90%;
            }
            .floatLeft {
                float:left;
            }
            .floatRight {
                float:right;
            }
            .gauche {
                text-align: left;
            }
            .droite {
                text-align: right;
            }
            .aDroite {
                float:right;
            }
            .centre {
                margin:0 auto;
                text-align: left;
            }
            .centrer {
                text-align: center;
            }
            .consigne {
                color:rgb(105, 105, 105);
                font-size:1.2em;
            }

            .sousTitre {
                border-radius: 10px 10px 0 0;
                background-color: $couleur-fondDivs;
                padding:1em 2em;
                vertical-align: top;
            }
            H4, H5, H6 {
                text-align: left;
                font-weight: bold;
                padding : 0;
                span {
                    font-size: 0.8em;
                    /*opacity:0.8;*/
                    opacity:1;
                    &.sousTitre > span {
                        color:#7B8691;
                    }
                }
            }
/*
            H2.projets {
                margin:1em 0 0.2em;
                background-color: #F2F2F2;
                color: #2c3e50;
                span {
                    opacity:0.6;
                }
            }
*/
            H4 {
                font-size: 1.4em;
            }
            H5 {
                font-size: 1.2em;
            }
            H6 {
                font-size: 1.1em;
            }


            .theme--light.v-icon {
                color:white;
            }
            .v-input.aCompleter {
                max-width: 100px;
                display: inline-block;
                width: 100%;
            }
            .v-input.complement {
                display: inline-block;
                max-width: 150px;
                width: 100%;
            }
            .v-input.v-select {
                background-color: transparent;
                padding:0;
                /*border-radius: 5px 5px 0 0;*/
                .v-input__slot {
                    padding : 0;
                }
            }
            .v-input.v-input--is-disabled {
                /*background-color: #C6D2DF;*/
                .v-input__slot {
                    background-color: #C6D2DF;
                }
                .v-select__slot {
                    background-color: #C6D2DF;
                    .v-select__selection {
                        color:$couleur-texte;
                    }
                }
                .v-label {
                    color:$couleur-texte;
                }
                input {
                    color: #777;
                }
            }

            .v-input.v-input--is-readonly {
                .v-text-field__details {
                    /*border-left: 34px solid #EDEDED;*/
                    box-sizing: content-box;
                    /*margin-left: -34px;*/
                }
            }

            .v-input:NOT(.v-input--checkbox):NOT(.v-input--radio-group)  {
                /*background-color: white;*/
                /*background-color: $couleur-fond;*/
                padding:1em 0 0;
                border-radius: 5px 5px 0 0;

                .v-input__icon {
                    padding-left: 0.6em;
                }

                .v-text-field__details {
                    /*background-color: #EDEDED;*/
                    background-color: transparent;
                }
                .v-input__slot {
                    padding : 1em 0.3em 0;
                    border: 1px solid $couleur-fond;
                    background-color: white;
                    &:before {
                        border-bottom-color: $couleur-fond;
                    }
                    .v-text-field__slot {
                        padding-bottom: 0.4em;
                    }
                }
                .v-select__slot {
                    border-radius: 5px 5px 5px 5px;
                    /*border: 1px solid $couleur-fond;*/
                    /*background-color: $couleur-fond;*/
                    background-color: white;
                    padding:1em 0.3em 0.4em;
                    border-bottom-color: $couleur-lien;

                    label {
                        left : 0.3em !important;
                        top: 1.3em !important;
                    }
                    .v-select__selection {
                        /*color:white;*/
                        color:$couleur-fond;
                    }
                }
                .v-label {
                    color:#B8C7D8;
                }
                input, textarea {
                    color:$couleur-lien;
                    background-color: transparent;
                    line-height: 1.2em;
                    padding: 1em 0;
                }
                input[type=number] {
                    text-align: right;
                    -moz-appearance: textfield;
                    appearance: textfield;
                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        margin-left:0.4em;
                    }
                }
            }
            .v-text-field>.v-input__control>.v-input__slot:before {
                border-color: #394F4E;
            }
            /*
            .v-text-field.v-select>.v-input__control>.v-input__slot:before {
                border-color: #EDEDED;
            }
            */
            .v-text-field>.v-input__control>.v-input__slot:after {
                border-color: transparent;
            }
            .v-text-field .v-label--active {
                transform: translateY(-22px) scale(.75);
            }
            .v-autocomplete__content {
                right: 0;
                left: unset !important;
            }

            .theme--light.v-btn.v-btn--has-bg {
                background-color: $couleur-lien;
                color: white;
                text-transform: none;
            }

            .champInactif {
                margin:1em 0;
                padding:23px 0.4em 15px;
                background-color: #8095AA;
                border-radius: 5px 5px 0 0;
                color:white;
                font-weight: bold;
                border-bottom: 15px solid #EDEDED;
            }

            .boutonsValidation {
                margin: 2em 0;
                button {
                    margin-right:1em;
                }
            }


            span.checkbox {
                div {
                    display: inline;
                }
                .v-input {
                    background-color: transparent;

                    .v-input__control {
                        height:20px;
                        .v-label {
                            color: $couleur-texte;
                            padding:0 0 0 10px;
                        }
                    }
                }
                .v-icon {
                    color: $couleur-fond;
                    width:auto;
                }
            }

            table.tableSTD {
                border-spacing : 0;
                border-collapse : collapse;

                tr {
                    /*
                    border-left: 10px solid $couleur-fondDivs;
                    border-right: 10px solid $couleur-fondDivs;
                    */
                    border-bottom: 2px solid transparent;
/*
                    td, th {
                        padding:0.6em 1em;
                        margin:0.4em 1em;

                        &.grand {
                            min-width:200px;
                            width:60%;
                        }

                        &.moyen {
                            min-width:100px;
                            width:30%;
                        }

                        &.historique, &.deadline, &.ico  {
                        }
                    }
*/

                    th {
                        text-align: center;
                        span {
                            margin-right:1em;
                        }
                    }
                    td {
                        background-color: $couleur-fondDivs;
                        &.historique  {
                            text-align: left;
                            min-width: 126px;
                            text-align: center;

                            /*span, li, div {                            */
                            span {
                                text-transform: uppercase;
                                display: inline-block;
                            }
                        }
                    }
                    &.seul {
                        td, th {
                            background-color: white;
                                tr:first-of-type > td {
                                    background-color: $couleur-fondDivs;
                                }
                        }
                    }
                    &:not(.seul) {
                        td, th {
                            tr > td {
                                background-color: $couleur-fondDivs;
                            }
                        }
                    }

                }
            }

            .listeHistorique {
                padding:0.4em 0.2em 0.4em 0.8em !important;
                .itemHistorique {
                    list-style-type: none;
                    color:$couleur-lien;
                    &:hover {
                        color:$couleur-hover;
                    }
                }
                .nouvelEtat {
                    font-size: 90%;
                    line-height: 1em;
                    margin:0;
                    padding-top: 0.2em;
                    margin-top: 0.2em;
                    border-top: 1px solid grey;
                    background-color: #efefef;
                }
            }


        }
        #back.container {
            table.tableSTD {

                tr {

                    td, th {
                        padding:0.6em 0.4em;
                        margin:0.4em 0.4em;

                        &.ico {
                            padding:0.6em 0.6em;
                            margin:0.4em 0.6em;
/*
                            min-width:20px;
                            max-width:20px;
                            width:20px;
*/
                        }
                        &.poubelle {
                            text-align: right;
                        }

                        &.avecCompteur {
                            padding-right:0;
                            padding-left:0.2em;
                        }

                        &.grand {
                            min-width:200px;
                            width:60%;
                        }

                        &.moyen {
                            min-width:260px;
                            width:20%;
                        }
                        &.historique  {
                            text-align: center;
                        }

                    }
                }
            }
        }
        .v-dialog--active {
            .poubelle {
                color:orangered;
                .v-card__text {
                    color:orangered;
                }
                .v-card__actions {
                    button {
                        span {
                            color:orangered;
                        }
                    }
                }
            }
            .dechetterie {
                color:red;
                .v-card__text {
                    color:red;
                }
                .v-card__actions {
                    button {
                        span {
                            color:red;
                        }
                    }
                }
            }
        }
    }

</style>
