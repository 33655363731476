<style lang="css">
   @import './assets/styles/Font.css';
   @import './assets/font/font-awesome/css/font-awesome.css';
</style>

<template>
  <div id="app" data-app="true">
    <div id="version">
        Version 1.2.2
    </div>
    <div id="nav">
      <router-link to="/back">Back</router-link> |
      <router-link to="/front">Front "Grand Est"</router-link>
    </div>
    <router-view/>
  </div>
</template>

<script>

// @ is an alias to /src
import { addeoPost, addeoGet } from "@/js/datas.js"

// icons
/*
import MenuIcon from 'vue-material-design-icons/Menu.vue';
Vue.component('menu-icon', MenuIcon);
*/
export default {
      name: 'PROJETS',
      components: {
      },

    data() {
        return {
            objEtats:null,
            tabPhases:null,
            ready:false
        }
    },

    created: function () {

        addeoGet.lireEtats().then(response => {
            if (response && response.retour && response.retour.success == true) {
                var etats = response.retour.data;
                this.traiterEtats(etats);

                addeoGet.lirePhases().then(response => {
                    if (response && response.success == true) {
                        var phases = response.data;
                        this.traiterPhases(phases);

                        this.ready = true;

                    }
                })
            }
        })

        //this.$router.push("/back").catch(()=>{});
    },

    methods: {
        traiterPhases(phases) {
            if (phases.length) {
                var thisObj = this;
                phases.forEach((phase, index) => {
                    phase[phase.code] = phase;
                })
            }
            this.tabPhases = phases;
        },
        traiterEtats(etats) {
            if (etats.length) {
                var objEtats = new Object();
                objEtats.projets = [];
                objEtats.ucs = [];
                objEtats.sprints = [];
                var thisObj = this;
                etats.forEach((etat, index) => {
                    if (etat.projet) {
                        objEtats.projets.push(etat);
                        objEtats.projets[etat.code] = etat; // pour accès direct
                    }
                    if (etat.uc) {
                        objEtats.ucs.push(etat);
                        objEtats.ucs[etat.code] = etat; // pour accès direct
                    }
                    if (etat.sprint) {
                        objEtats.sprints.push(etat);
                        objEtats.sprints[etat.code] = etat; // pour accès direct
                    }

                })

                objEtats.projets.sort(this.triOrdre);
                objEtats.ucs.sort(this.triOrdre);
                objEtats.sprints.sort(this.triOrdre);
                this.objEtats = objEtats;
            }
        },

        triOrdre(x, y) {
            return(x.ordre - y.ordre); // tri ascendant
        }
    }
}

</script>

<style lang="scss">
#app {
    /*font-size: 14px;*/
    font-family: robotoregular, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /*color: #2c3e50;*/
    /*color: #2c3e50;*/
    color: #4D5C6B;
}
#version {
    text-align: right;
    font-size: 0.8em;
    color: #ddd;
    margin:0 12px -10px 0;
}

#nav {
    display:none;
    padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
