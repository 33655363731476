<template>
    <div id="editerPdfsProjet" class="contenu">
        <div v-if="!nouveauPdf" class="sousTitre">
            <h5 class="aGauche" v-html="h4Pdf">
            </h5>
            <div class="aDroite">
                <v-btn  class="a-btn prioritaire" type="button" @click="nouveauPdf=true"><i class="fa fa-plus svg" aria-hidden="true"></i>&nbsp;{{titreCheckBox}}</v-btn>
            </div>
            <div class="clear"></div>
        </div>
<!--
        <span class="checkbox">
            <v-checkbox v-if="!nouveauPdf"
                v-model="nouveauPdf"
                :label="titreCheckBox"
                on-icon="fa fa-check-square"
                off-icon="fa fa-square"
            ></v-checkbox>
        </span>
-->
            <div class="conteneurUpload" v-if="nouveauPdf">


                <h3><span class="etape">1/ Dépôt de fichier ou lien</span></h3>
                <div class="container" v-if="!erreurs && form.lien == ''">
                      <!--UPLOAD-->
                      <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                        <div class="dropbox">
<!--
                            <input type="file" :name="uploadFieldName" :disabled="isSaving" @change.prevent="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                            accept="application/pdf, image/*, application/vnd.ms-powerpoint, .mm" class="input-file"
-->
                            <input type="file" :name="uploadFieldName" :disabled="isSaving" @change.prevent="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" class="input-file"
                            @dragover="focusUpload"
                            @drop.stop="focusUpload"
                            ref="uploadPDF">

                            <p v-if="isInitial">
                              Glissez/déposez votre fichier ici pour lancer l'importation<br> ou cliquez pour le rechercher
                            </p>
                            <p v-if="isSaving">
                              Uploading {{ fileCount }} files...
                            </p>
                        </div>
                      </form>
                    <div class="output" v-if="formData">Le fichier {{uploadedFileName}} a été téléchargé</div>
                </div>
                <div id="erreursContainer" v-if="erreurs">
                    <div id="erreurs">
                        <ul>
                            <li v-for="erreur in tabErreurs" >
                                {{erreur}}
                            </li>
                        </ul>
                    </div>
                    <v-btn class="boutonFonctionObjet prioritaire centrer" type="button" @click.stop="reset">Recommencer</v-btn>
                </div>

                    <v-form v-if="ready">
                        <div class="centrer"  v-if="form.fichier == '' && form.lien == ''">
                            <span class="centre consigne">ou indiquez un lien</span>
                        </div>
                        <v-text-field  v-if="form.fichier == ''"
                                v-model="form.lien"
                                label="Url du lien : "
                                ref="lien"
                        ></v-text-field>

                        <h3><span class="etape">2/ Informations associées</span></h3>

                        <v-text-field
                                v-model="form.titre"
                                :error-messages="error.titre"
                                label="Titre du fichier ou du lien : *"
                                required
                                @input="$v.form.titre.$touch()"
                                @blur="$v.form.titre.$touch()"
                                ref="titre"
                        ></v-text-field>

                        <v-text-field v-if="form.fichier"
                                v-model="form.fichier"
                                label="Nom du fichier: *"
                                disabled
                        ></v-text-field>

                        <v-textarea
                                v-model="form.description"
                                label="description / description :"
                        ></v-textarea>

                        <div class="boutonsValidation">
                            <v-btn   class="a-btn prioritaire" type="button" @click="demanderMaj">Valider</v-btn>
                            <v-btn   class="a-btn " type="button" @click="demanderAbandonnerMaj">Abandonner</v-btn>
                        </div>

                    </v-form>

            </div>


            <v-dialog v-model="dialogError" v-if="errorDemandeMaj" @click:outside="fermerDialogError()" >
                 <v-card>
                     <v-card-title class="headline">Enregistrement du document</v-card-title>
                     <v-card-text>
                         <span v-if="errorDemandeMaj">{{errorDemandeMaj}}</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        <v-dialog v-model="confirmDialog">
                <v-card>
                    <v-card-title class="headline">Confirmation</v-card-title>
                    <v-card-text>
                        <div>Voulez-vous abandonner votre saisie d'un document ?</div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="reprendreSaisie">Non</v-btn>
                        <v-btn color="" text @click="abandonnerSaisie">Oui</v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
    import { ApiUpload } from "../../js/upload.js"

    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    export default {
        mixins: [validationMixin],
        name: 'projetPdf',
        components: {
        },
        validations: {
            form: {
                titre: { required, maxLength: maxLength(256) },
                description: {   },
            }
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,
            lePdf: Object,
        },

        data() {
            return {
                leProjet:this.leContexte.projet,
                laUc:this.leContexte.uc,
                leSprint:this.leContexte.sprint,
                nouveauPdf:false,
                form: {
                    id:0,
                    fichier: "",
                    lien: "",
                    titre: "",
                    description:"",
                    url:"",
                    projet_id:0,
                    uc_id:0,
                    sprint_id:0,
                    visibilite:false
                },
                old: null,
                error: {
                    code: "",
                    titre: "",
                    description:"",
                },
                confirmDialog:false,
                dialogError:false,
                errorDemandeMaj:false,
                messageMaj:"",
                messageSucces:"Le document est enregistré !",
                ready:false,
                // upload
                //uploadedFile: null,
                uploadedFileName: "",
                uploadError: null,
                currentStatus: null,
                uploadFieldName: 'lePDF',
                erreurs:false,
                formData:null
            }
        },


        watch: {
            lePdf(newValue) {
                this.reset();
                if (newValue) {
                    this.form.id = this.lePdf.id;
                    this.form.fichier = this.lePdf.fichier;
                    this.form.lien = this.lePdf.lien;
                    this.form.titre = this.lePdf.titre;
                    this.form.description = this.lePdf.description;
                    this.form.url = this.lePdf.url;
                    this.form.projet_id = this.lePdf.projet_id;
                    this.form.uc_id = this.lePdf.uc_id;
                    this.form.sprint_id = this.lePdf.sprint_id;
                    this.form.visibilite = this.lePdf.visibilite;
                    this.old = JSON.parse(JSON.stringify(this.form));
                    this.nouveauPdf = true;
                }
            },
            nouveauPdf(newValue) {
                if (!newValue) {
                    this.initForm();
                    this.$emit('editionEnCours', false);
                }
                else {
                    if (this.ready) {
                        if (!this.lePdf) {
                            this.reset();
                        }
                        this.focusUpload();
                    }
                    this.$emit('editionEnCours', true);
                }
            },
            ready(newValue) {
                if (newValue) {
                    this.initForm();
                }
            },
        },

        created: function () {
            this.reset();
        },

        mounted: function () {
        },

        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            },
            titreCheckBox() {
                if (this.form.id) {
                    return('Modification de "' +this.form.titre+'"');
                }
                //return("Déposer un nouveau fichier, indiquer un lien");
                return("Nouveau document");
            },
            h4Pdf() {

                var retour ='<i class="fa fa-folder-open svg" aria-hidden="true"></i>&nbsp;Documents';
                if (this.leSprint) {
                    retour += " du Sprint " + this.leSprint.code + "<br/><span>" + this.leSprint.libelle;
                }
                else {
                    if (this.laUc) {
                        retour += " de l'UC " + this.laUc.code + "<br/><span>" + this.laUc.libelle;
                    }
                    else {
                        retour += " du Projet " + this.leProjet.code + "<br/><span>" + this.leProjet.libelle;
                    }
                }
                 retour += "</span>";
                return(retour);
            },
            titreErrors () {
                if (!this.$v.form.titre.$invalid) return false // verifier invalid
                if (!this.$v.form.titre.required) {
                     this.error.titre  = "Vous devez saisir le titre du document"
                }
                this.$refs.titre.focus();
                return true
            },
            descriptionErrors () {
                if (!this.$v.form.description.$invalid) return false // verifier invalid
                /*
                if (!this.$v.form.description.required) {
                     this.error.description  = "Vous devez saisir la description"
                }
                */
                this.$refs.description.focus();
                return true
            },

        },
        methods: {
            focusUpload() {
                if (this.$refs.uploadPDF) {
                    this.$refs.uploadPDF.focus();
                }
            },
            reset() {
                // infos du formulaire
                this.initForm();

                // upload
                this.currentStatus = STATUS_INITIAL;
                //this.uploadedFile = null;
                this.uploadedFileName = "";
                this.uploadError = null;
                this.tabErreurs = [];
                this.erreurs = false;
                this.dialog = false;
                this.message = "";
                this.confirmDialog=false;

                this.formData = null;

                this.ready = true;

                return; // faut-il lire un truc ?
              },
              save(formData) {
                // upload data to the server
                this.currentStatus = STATUS_SAVING;
                  var thisObj = this;
                ApiUpload.upload(formData)
                  .then(x => {
                    thisObj.uploadedFile =x[0].pdf;
                    thisObj.uploadedFileName = x[0].name;
/**/
                        const u8Array = new Uint8Array(x[0].pdf);
                        const url = window.URL.createObjectURL(x[0].pdf);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', thisObj.uploadedFileName); //or any other extension
                        document.body.appendChild(link);
                        link.click();
/**/
                    thisObj.currentStatus = STATUS_SUCCESS;
                    thisObj.traiterPDF();
                  })
                  .catch(err => {
                    this.tabErreurs[0] = err.response;
                    this.erreurs = true;
                    this.currentStatus = STATUS_FAILED;
                  });
              },
              filesChange(fieldName, fileList) {
                  // handle file changes
                  const formData = new FormData();
                  // un seul fichier
                  if (!fileList.length) {
                      this.tabErreurs[0] = "Attention, vous ne pouvez importer qu'un seul fichier à la fois";
                      this.erreurs = true;
                      return;
                  }

                  // PDF
                  var name = fileList[0].name;
                  var extension = name.substring(name.length-4, name.length).toUpperCase();
                  if ((extension != ".PDF")
                            && (extension != ".ZIP") && (extension != ".XML")
                            && (extension != ".ODT") && (extension != ".ODS") && (extension != ".ODP")
                            && (extension != ".XLS") && (extension != ".DOC") && (extension != ".PPT")
                            && (extension != ".JPG") && (extension != ".PNG")) {
                      extension = name.substring(name.length-6, name.length).toUpperCase();
                      if (extension != ".XMIND") {
                          extension = name.substring(name.length-5, name.length).toUpperCase();
                          if ((extension != ".XLSX") && (extension != ".DOCX") && (extension != ".PPTX") && (extension != ".PPSX")) {
                                extension = name.substring(name.length-3, name.length).toUpperCase();
                                if ((extension != ".MM")) {
                                      this.tabErreurs[0] = "Attention, ce format n'est pas autorisé en upload ... ";
                                      this.erreurs = true;
                                      return;
                                }
                          }
                      }
                  }


// 2021/11/08
//  changement du principe
// le pdf est uploadé seul
// une fois qu'on a l'id du document
                  this.file = fileList[0];
                  this.uploadedFileName = this.file.name;
		          this.formData = new FormData();
		          this.formData.append('fichier', this.file);
                  this.currentStatus = STATUS_SUCCESS;
                  this.traiterPDF();
/*
                  // Display the key/value pairs
for (var pair of this.formData.entries()) {
    console.log(pair[0]+ ', ' + pair[1]);
}
*/
/*
                // append the files to FormData
                Array
                  .from(Array(fileList.length).keys())
                  .map(x => {
                    formData.append(fieldName, fileList[x], fileList[x].name);
                  });

                // save it
                this.save(formData);
*/
              },
            traiterPDF() {
                //this.uploadedFile
                this.form.fichier = this.uploadedFileName;
                //this.form.uploadedFile = this.uploadedFile;
                this.$refs.titre.focus();
            },
            initForm() {
                this.form.id = 0;
                this.form.titre = "";
                this.form.lien = "";
                this.form.fichier = "";
                this.form.description = "";
                this.form.url = this.leContexte.client.code + "/" + this.leContexte.famille.code + "/" + this.leProjet.code;
                if (this.leSprint) {
                    this.form.url += "/" + this.laUc.code + "/" + this.leSprint.code;
                    this.form.sprint_id = this.leSprint.id;
                    this.form.uc_id = 0;
                    this.form.projet_id = 0;
                }
                else {
                    this.form.sprint_id = 0;
                    if (this.laUc) {
                        this.form.url += "/" + this.laUc.code;
                        this.form.uc_id = this.laUc.id;
                        this.form.projet_id = 0;
                    }
                    else {
                        this.form.uc_id = 0;
                        this.form.projet_id = this.leProjet.id;
                    }
                }
                //this.form.uploadedFile = null;
/*
                // on remet aussi à jour le "preUpload"
                this.uploadedFile = null;
                this.uploadedFileName = "";
                this.uploadError = null;
                this.currentStatus = null;
                this.erreurs = false;
*/
            },
            reinitErrorMessages() {
            },

            abandonnerSaisie() {
                this.confirmDialog=false;
                this.nouveauPdf=false;
                this.$emit('retourProjetPdf', null);
            },
            reprendreSaisie() {
                this.confirmDialog=false;
            },
            demanderAbandonnerMaj() {
                this.confirmDialog=true;
            },
            demanderMaj() {
                this.reinitErrorMessages();
                this.$v.$touch();
                var titreErrors = this.titreErrors;
                var descriptionErrors = this.descriptionErrors;
                if ( (this.$v.$invalid &&
                      (titreErrors || descriptionErrors)) ) {

                    console.log("submit pas OK");
                    return;
                }

                // si pas de modification des données
                if (this.old && (JSON.stringify(this.old) === JSON.stringify(this.form))) {
                    this.errorDemandeMaj = "Le document est inchangé, inutile de l'enregistrer !";
                    this.dialogError = true;
                    return
                }
                if (!this.form.fichier && !this.form.id && (this.form.lien == "")) { // il faut un fichier, mais on l'a peut-^tre déjà :)
                    this.errorDemandeMaj = "Le document doit contenir un dépôt ou un lien !";
                    this.dialogError = true;
                    return
                }

                var thisObj = this;
                addeoPost.majDocument(this.form)
                .then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        if (thisObj.form.fichier != "" && thisObj.formData) {
                            this.demanderMajFichier(response.retour.data.relDocument.id);
                        }
                        thisObj.errorDemandeMaj = thisObj.messageSucces;
                        thisObj.dialogError = true;
                        thisObj.$emit('retourProjetPdf', response.retour.data);
                    }
                    else {
                        this.dialogError = true;
                        var complementErreur = "merci de nous contacter";
                        if (response && response.erreur) {
                            complementErreur = response.erreur;
                        }
                        this.errorDemandeMaj = "Enregistrement impossible : " + complementErreur;
                        console.log("demanderMaj non valide")
                    }
                })

            },

            demanderMajFichier(idPdf) {
                    addeoPost.majDocumentFichier(this.formData, idPdf)
                    //addeoPost.majDocumentFichier(this.file, idPdf)
                    .then(response => {
                        if (response && response.retour && response.retour.success == true) {
                            console.log("majDocumentFichier")
                        }
                        else {
                            this.dialogError = true;
                            var complementErreur = "merci de nous contacter";
                            if (response && response.erreur) {
                                complementErreur = response.erreur;
                            }
                            this.errorDemandeMaj = "Enregistrement impossible : " + complementErreur;
                            console.log("majDocumentFichier non valide")
                        }
                    })
            },

            fermerDialogError(data) {
                this.dialogError = false;

                if (this.errorDemandeMaj) {
                    if (this.errorDemandeMaj === this.messageSucces) {
                        this.nouveauPdf = false;
                        this.initForm();
                    }
                    else {
                        this.$nextTick(() => {
                            this.$refs.titre.focus();
                        })
                    }
                }
                this.errorDemandeMaj = false;
            },

        }
    }
</script>

<style lang="scss">
    #app {
        #editerPdfsProjet {

            .conteneurUpload {
                h3 {
                    background-color: transparent;
                }
            }

            .edition {
                margin:1em 0 0 4em;
            }

            .etape {
                font-size:1.8em;
                color:#777;
                font-style: italic;
            }

            #erreursContainer {
                text-align: center;
                margin:1em auto 2em;
            }
            #erreurs {
                background-color: rgba(255, 151, 0, 0.4);
                border: 1px solid #cccccc;
                width:100%;
                /*max-width: 800px;*/
                margin: 2em 0;
                padding:2em 1em 1em 2em;
                text-align: left;
                font-size:0.9em;
                font-style: italic;

                li {
                    margin-bottom:1em;
                }
            }

              .dropbox {
                outline: 2px dashed grey; /* the dash box */
                outline-offset: -10px;
                background: white;
                color: dimgray;
                padding: 10px 10px;
                min-height: 200px; /* minimum height */
                position: relative;
                cursor: pointer;
              }

              .input-file {
                opacity: 0; /* invisible but it's there! */
                width: 100%;
                height: 200px;
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
              }

              .dropbox:hover {
                background: lightblue; /* when mouse over to the drop zone, change color */
              }

              .dropbox p {
                font-size: 1.2em;
                text-align: center;
                padding: 50px 0;
              }
            .output {
                font-style: italic;
                font-size:0.8em;
                color:#223938;
            }
        }
    }



</style>
