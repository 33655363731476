<template>
    <div id="projets" class="contenu">
        <div class="">
            <div v-if="ready">
                <span v-if="lesProjets && !lesProjets.length">Pas encore de projets !</span>
                <table id="listeProjets" class="tableSTD" v-if="lesProjets && lesProjets.length">
                    <tr class="tris" v-if="vueListe || lesProjets.length == 1">
                        <th></th>
                    </tr>
                    <tr class="tris" v-if="!vueListe && lesProjets.length>1">
                        <th class="ico">
                        </th>
                        <th class="lien colspan5" @click.stop="trierCode">
                            <span>Libellé</span>
                            <i v-if="triEnCours != 'CODEDESC' && triEnCours != 'CODEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueEtat">
                            <span>Etat</span>
                            <i v-if="triEnCours != 'ORDREDESC' && triEnCours != 'ORDREASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueDate">
                            <span>Début</span>
                            <i v-if="triEnCours != 'DATEDESC' && triEnCours != 'DATEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="colspan2" >&nbsp;</th>
                    </tr>

                    <tr class="itemProjet"  v-for="(leProjet, numProjet) in lesProjets" :id="numProjet" :key="numProjet" v-bind:class="{seul : vueListe == leProjet.id, pair: Math.trunc(numProjet/2)*2==numProjet, ok: leProjet.UCs}"  >
                        <td colspan="10" class="colspanGroup colspanGroup10" v-if="1 || (!vueListe || (vueListe == leProjet.id))">
                            <table class="tableSTD">
                                <tr>
                                    <td v-if="vueListe != leProjet.id" class="ico">
                                        <span @click.stop="chargerUCs(leProjet)" class="lien" title="Ouvrir la liste des UCs du projet">
                                            <i class="fa fa-plus svg zoneClient" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td v-if="vueListe == leProjet.id" class="ico">
                                        <span @click.stop="chargerUCs(leProjet)" class="lien" title="Refermer la liste des UCs du projet">
                                            <i  class="fa fa-minus svg zoneClient" aria-hidden="true"></i>
                                        </span>
                                    </td>

                                    <td v-if="vueInfos != leProjet.id" class="libelle grand">
                                        {{leProjet.code}} - {{leProjet.libelle}}
                                       <span @click.stop="voirInfos(leProjet)" title="Obtenir la description et la documentation du projet" class="lien noFloat zoneClient">
                                           &nbsp;-&nbsp;
                                            <span class="noFloat noRC">
                                                (voir)
                                                <span class="noFloat" v-if="leProjet.nbDocs">
                                                {{leProjet.nbDocs}}&nbsp;
                                                <i class="fa fa-file svg petit" aria-hidden="true"></i>
                                                </span>
                                            </span>
                                        </span>
                                        <!-- &nbsp;&nbsp;<i class="fa fa-plus svg" aria-hidden="true"></i> -->
                                    </td>
                                    <td v-if="vueInfos == leProjet.id" class="libelle grand">
                                        {{leProjet.code}} - {{leProjet.libelle}}
                                        <span @click.stop="voirInfos(leProjet)" title="Refermer la description et la documentation du projet" class="lien noFloat zoneClient">&nbsp;-&nbsp;
                                            <span class="noFloat">(refermer)</span>
                                        </span>
                                    </td>

                                    <td colspan="2" class="historique" @mouseover="numActiveCourant = numProjet" @mouseleave="numActiveCourant = -1">
                                        <div class="historiqueGauche">
                                            <span>
                                                {{leProjet.historique[0].libelleEtat}}
                                            </span>
                                        </div>
                                        <div class="historiqueDroite">
                                            au
                                            <span>
                                                {{leProjet.historique[0].dateAffichee}}
                                            </span>
                                        </div>
                                        <div class="listeHistorique" v-if="numActiveCourant == numProjet"  >
                                            <ul>
                                                <li class="itemHistorique" v-for="(leHistorique, numHistorique) in leProjet.historique">
                                                <div class="historiqueGauche">
                                                    <span>
                                                        {{leHistorique.libelleEtat}}
                                                    </span>
                                                </div>
                                                <div class="historiqueDroite">
                                                    au
                                                    <span>
                                                        {{leHistorique.dateAffichee}}
                                                    </span>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>


                                    <td  class="centrer nbItems" >
                                        <div v-if="!leProjet.nbUcs">&nbsp;</div>
                                        <div v-if="leProjet.nbUcs">
                                            <span  class=" ico centrer plusPetit">{{leProjet.nbUcs}}&nbsp;UCs</span>
                                        </div>
                                    </td>

                                </tr>

<transition name="slide-fade" >
                                <tr v-if="leContexte.projet && (vueInfos == leProjet.id)" class="infosDocs">
                                    <td colspan="5" class="colspanGroup">
                                        <InfosDocs :leContexte="leContexteProjet"  />
                                    </td>
                                </tr>
</transition>
<transition name="slide-fade" >
                                <tr v-if="leContexte.projet && (vueListe == leProjet.id)">
                                    <td colspan="5" class="colspanGroup">
                                        <Ucs :leContexte="leContexte"  />
                                    </td>
                                </tr>
</transition>
                            </table>
                        </td>
                    </tr>
                </table>



                <div class="archives" v-if="lesProjetsArchives && lesProjetsArchives.length">
                    Archives
                    <i v-if="!blocVisible" class="fa fa-solid fa-caret-down lien" @click.stop="blocVisible=!blocVisible"></i>
                    <i v-if="blocVisible" class="fa fa-solid fa-caret-up lien" @click.stop="blocVisible=!blocVisible"></i>
                </div>

                <table id="listeProjetsArchives" ref="listeProjetsArchives" class="tableSTD" v-if="blocVisible && lesProjetsArchives && lesProjetsArchives.length">
<!--
                    <tr class="tris" v-if="vueListe || lesProjetsArchives.length == 1">
                        <th></th>
                    </tr>
-->
                    <tr class="itemProjet"  v-for="(leProjet, numProjet) in lesProjetsArchives" :id="numProjet" :key="numProjet" v-bind:class="{seul : vueListe == leProjet.id, pair: Math.trunc(numProjet/2)*2==numProjet, ok: leProjet.UCs}"  >
                        <td colspan="10" class="colspanGroup colspanGroup10" v-if="1 || (!vueListe || (vueListe == leProjet.id))">
                            <table class="tableSTD">
                                <tr>
                                    <td v-if="vueListe != leProjet.id" class="ico">
                                        <span @click.stop="chargerUCs(leProjet)" class="lien" title="Ouvrir la liste des UCs du projet">
                                            <i class="fa fa-plus svg zoneClient" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td v-if="vueListe == leProjet.id" class="ico">
                                        <span @click.stop="chargerUCs(leProjet)" class="lien" title="Refermer la liste des UCs du projet">
                                            <i  class="fa fa-minus svg zoneClient" aria-hidden="true"></i>
                                        </span>
                                    </td>

                                    <td v-if="vueInfos != leProjet.id" class="libelle grand">
                                        {{leProjet.code}} - {{leProjet.libelle}}
                                       <span @click.stop="voirInfos(leProjet)" title="Obtenir la description et la documentation du projet" class="lien noFloat zoneClient">
                                           &nbsp;-&nbsp;
                                            <span class="noFloat noRC">
                                                (voir)
                                                <span class="noFloat" v-if="leProjet.nbDocs">
                                                {{leProjet.nbDocs}}&nbsp;
                                                <i class="fa fa-file svg petit" aria-hidden="true"></i>
                                                </span>
                                            </span>
                                        </span>
                                        <!-- &nbsp;&nbsp;<i class="fa fa-plus svg" aria-hidden="true"></i> -->
                                    </td>
                                    <td v-if="vueInfos == leProjet.id" class="libelle grand">
                                        {{leProjet.code}} - {{leProjet.libelle}}
                                        <span @click.stop="voirInfos(leProjet)" title="Refermer la description et la documentation du projet" class="lien noFloat zoneClient">&nbsp;-&nbsp;
                                            <span class="noFloat">(refermer)</span>
                                        </span>
                                    </td>

                                    <td colspan="2" class="historique" @mouseover="numActiveCourant = numProjet" @mouseleave="numActiveCourant = -1">
                                        <div class="historiqueGauche">
                                            <span>
                                                {{leProjet.historique[0].libelleEtat}}
                                            </span>
                                        </div>
                                        <div class="historiqueDroite">
                                            au
                                            <span>
                                                {{leProjet.historique[0].dateAffichee}}
                                            </span>
                                        </div>
                                        <div class="listeHistorique" v-if="numActiveCourant == numProjet"  >
                                            <ul>
                                                <li class="itemHistorique" v-for="(leHistorique, numHistorique) in leProjet.historique">
                                                <div class="historiqueGauche">
                                                    <span>
                                                        {{leHistorique.libelleEtat}}
                                                    </span>
                                                </div>
                                                <div class="historiqueDroite">
                                                    au
                                                    <span>
                                                        {{leHistorique.dateAffichee}}
                                                    </span>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>


                                    <td  class="centrer nbItems" >
                                        <div v-if="!leProjet.nbUcs">&nbsp;</div>
                                        <div v-if="leProjet.nbUcs">
                                            <span  class=" ico centrer plusPetit">{{leProjet.nbUcs}}&nbsp;UCs</span>
                                        </div>
                                    </td>

                                </tr>

<transition name="slide-fade" >
                                <tr v-if="leContexte.projet && (vueInfos == leProjet.id)" class="infosDocs">
                                    <td colspan="5" class="colspanGroup">
                                        <InfosDocs :leContexte="leContexteProjet"  />
                                    </td>
                                </tr>
</transition>
<transition name="slide-fade" >
                                <tr v-if="leContexte.projet && (vueListe == leProjet.id)">
                                    <td colspan="5" class="colspanGroup">
                                        <Ucs :leContexte="leContexte"  />
                                    </td>
                                </tr>
</transition>
                            </table>
                        </td>
                    </tr>
                </table>

            </div>

        </div>

    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
    import Ucs from '@/components/front/FUcs.vue'
    import InfosDocs from '@/components/front/InfosDocs.vue'


    export default {
        name: 'projets',
        components: {
            Ucs,
            InfosDocs,
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                laFamilleId:this.leContexte.famille.id,
                projets:null,
                projetsArchives:null,
                blocVisible:false,
                ready:false,
                numActiveCourant:-1,
                triEnCours:"",
                vueInfos:false,
                vueListe:false,
                myTimeout:null
            }
        },


        watch: {
            leContexte(newValue) {
                if (newValue) {
                    this.laFamilleId = this.leContexte.famille.id,
                    this.lireProjets();
                }
            },
            blocVisible(newValue) {
                if (newValue) {
                    this.myTimeout = setTimeout(this.scrollBottom, 100);
                }
            }
        },

        created: function () {
            if (this.laFamilleId) {
                this.lireProjets();
            }

        },

        mounted: function () {
        },

        computed: {
            lesProjets() {
                return(this.projets);
            },
            lesProjetsArchives() {
                return(this.projetsArchives);
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.projets);
                }
                return(null);
            },
            leContexteProjet() {
                var contexteProjet = JSON.parse(JSON.stringify(this.leContexte));
                contexteProjet.uc = null;
                contexteProjet.sprint = null;
                return(contexteProjet)
            }
        },

        methods: {
            scrollBottom() {
                if (this.myTimeout) {
                    clearTimeout(this.myTimeout);
                }
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            // modification suite à demande Jacques du 18/01/2023
            trierCode() {
                //wiz
                if (this.triEnCours == "CODEDESC") {
                    this.triEnCours = "CODEASC";
                }
                else {
                    this.triEnCours = "CODEDESC";
                }
                this.projets.sort(this.triCode);
                this.projets = JSON.parse(JSON.stringify(this.projets));
                // idem pour archives
                this.projetsArchives.sort(this.triCode);
                this.projetsArchives = JSON.parse(JSON.stringify(this.projetsArchives));
            },
            triCode(x, y) {
                const anneeCodeX = x.code.substring(0, 4);
                const anneeCodeY = y.code.substring(0, 4);
                const suiteCodeX = x.code.substring(4);
                const suiteCodeY = y.code.substring(4);
                // on commence par trier par année
                if (this.triEnCours == "CODEASC") {
                    if (anneeCodeX < anneeCodeY) {
                        return(-1)
                     }
                    if (anneeCodeY < anneeCodeX) {
                        return(1)
                     }
                }
                else {
                    if (anneeCodeX < anneeCodeY) {
                        return(1)
                     }
                    if (anneeCodeY < anneeCodeX) { // tri descendant
                        return(-1)
                     }
                }
                // puis on tri par le reste mais toujours en "ascendant"
                if (suiteCodeX < suiteCodeY) {
                    return(-1)
                }
                return(1)
                /*
                if (this.triEnCours == "CODEASC") {
                    if (x.code < y.code) {
                        return(-1)
                     }
                    if (y.code < x.code) {
                        return(1)
                     }
                }
                else {
                    if (x.code < y.code) {
                        return(1)
                     }
                    if (y.code < x.code) { // tri descendant
                        return(-1)
                     }
                }
                */
            },
            // FIN modification suite à demande Jacques du 18/01/2023
            trierHistoriqueEtat() {
                if (this.triEnCours == "ORDREASC") {
                    this.triEnCours = "ORDREDESC";
                }
                else {
                    this.triEnCours = "ORDREASC";
                }
                this.projets.sort(this.triOrdre);
                this.projets = JSON.parse(JSON.stringify(this.projets));
            },
            triOrdre(x, y) {
                if (this.triEnCours == "ORDREDESC") {
                    return(y.historique[0].ordre - x.historique[0].ordre); // tri ascendant
                }
                else {
                    return(x.historique[0].ordre - y.historique[0].ordre); // tri descendant
                }
            },
            trierHistoriqueDate() {
                if (this.triEnCours == "DATEASC") {
                    this.triEnCours = "DATEDESC";
                }
                else {
                    this.triEnCours = "DATEASC";
                }
                this.projets.sort(this.triDate);
                this.projets = JSON.parse(JSON.stringify(this.projets));
            },
            triDate(x, y) {
                if (this.triEnCours == "DATEASC") {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(-1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) {
                        return(1)
                     }
                }
                else {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) { // tri descendant
                        return(-1)
                     }
                }
            },

            voirInfos(leProjet) {
                if (this.vueInfos == leProjet.id) {
                    this.vueInfos = false;
                }
                else {
                    this.vueListe = false;
                    this.vueInfos = leProjet.id;
                    this.leContexte.projet = leProjet;
                }
            },

            chargerUCs(leProjet) {
                if (this.vueListe == leProjet.id) {
                    this.vueListe = false;
                }
                else {
                    this.vueInfos = false;
                    this.vueListe = leProjet.id;
                    this.leContexte.projet = leProjet;
                }
            },

            lireProjets() {
                var thisObj = this;
                addeoGet.lireProjets(thisObj.laFamilleId).then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        //thisObj.projets = response.retour.data;

                        const tabProjets = response.retour.data;
                        thisObj.projets = tabProjets.filter(projet => !projet.archive);
                        thisObj.projetsArchives = tabProjets.filter(projet => projet.archive);

                        // libelle états des historiques
                        thisObj.projets.forEach((projet, indexProjet) => {
                            var unHistorique = null;
                            projet.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                historique.libelle = lEtat.libelle +" au "+ historique.modified.substr(0, 10);
                                historique.libelleEtat = lEtat.libelle;
                                historique.dateEtat = historique.modified.substr(0, 10);
                                historique.dateAffichee = this.traiterDate(historique.dateEtat);
                                historique.ordre = lEtat.ordre;
                            })
                        })

                        // libelle états des historiques des archives
                        thisObj.projetsArchives.forEach((projet, indexProjet) => {
                            var unHistorique = null;
                            projet.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                historique.libelle = lEtat.libelle +" au "+ historique.modified.substr(0, 10);
                                historique.libelleEtat = lEtat.libelle;
                                historique.dateEtat = historique.modified.substr(0, 10);
                                historique.dateAffichee = this.traiterDate(historique.dateEtat);
                                historique.ordre = lEtat.ordre;
                            })
                        })
                        thisObj.triEnCours = "";
                        thisObj.trierCode();
                        thisObj.ready = true;
                    }
                })
            },
            traiterDate(datePasAffichee) {
                var tab = datePasAffichee.split('-')
                var dateAffichee = tab[2]+"-"+tab[1]+"-"+tab[0];
                return(dateAffichee);

            },
        }
    }
</script>

<style lang="scss">
    #app {

/*
.slide-enter-active, .fade-leave-active, .fade-slide-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    max-height:400px;

}
.slide-enter, .slide-leave-to {

    transform: scale(0);
    opacity: 0;

    max-height:0;
}
    */


        #front {
            #listeProjets, #listeProjetsArchives {
/*
                max-width: 1000px;
                min-width: 400px;
                width: 90%;
                background-color: #786346;
*/
                .tris {
                    th:NOT(.ico) {
                        width:11%;
                        &.colspan5 {
                            /*width:54%;*/
                            /*width:60%;*/
                            width:70%;
                        }
                        /*
                        &.colspan3 {
                            width:12%;
                        }
                        */
                        &.colspan2 {
                            width:16%;
                        }
                    }
                }

                tr, td {
                    position:relative;
                }
                td {
                    vertical-align: middle;
/*
                    padding:0.6em 1em;
                    margin:0.4em 1em;
*/
/*
                    &.grand {
                        min-width:200px;
                        width:55%;
                    }

                    &.moyen {
                        min-width:100px;
                        width:35%;
                    }
*/
                    &.historique {
                        /*vertical-align: top;*/
                    }

                    .listeHistorique {
                        position:absolute;
                        z-index: 2;
                        left:0;
                        top:0;
                        background-color: #eee;
                        border:#bbb solid 1px;
                        padding:0.4em 2em;
                        width:100%;
                        min-height: 100%;
                        box-sizing: border-box;
                        >div {
                            margin-bottom: 0.2em;
                        }
                    }
                }
            }

            #listeProjetsArchives {
                margin-bottom:2em;
            }
            .archives {
                border-radius: 6px 6px 0 0;
                background-color: #DDDDDD;
                margin:2em 0 0.6em;
                padding:0.6em;

                i {
                    margin-left: 0.6em;
                    margin-top: 0;
                    vertical-align: top;
                    font-size: 1.6em;
                }
            }

        }

    }



</style>
