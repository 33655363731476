<template>
    <div id="contexte" class="contenu">
        <img alt="Vue logo" class="logo aGauche" src="../../../public/img/addeo.png">
        <div class="infosContexte aGauche" v-if="ready">
            <h1>{{client.libelle}}</h1>
            <h2>{{famille.libelle}}</h2>
        </div>
        <div class="clear"></div>
        <div class="edition"  v-if="!leContexte || (!leContexte.editionEnCours && !leContexte.projet)" >
            <v-form v-if="ready">


                <div class="aGauche">
                    <h4 class="titreListe" >Client</h4>
                    <v-select  return-object
                        v-model="client"
                        item-text="libelle"
                        item-value="id"
                        :items="lesClients"
                        label="Choisir"
                        required
                        minValue=1
                    ></v-select>
                </div>

                <div class="aGauche">
                    <h4 class="titreListe" >Familles</h4>
                    <v-select  return-object
                        v-model="famille"
                        item-text="libelle"
                        item-value="id"
                        :items="lesFamilles"
                        label="Choisir"
                        required
                        minValue=1
                    ></v-select>
                </div>
                <div class="clear">
                </div>

            </v-form>

        </div>
    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
/*
    import { ApiTools } from "../../js/api/index.js"
*/
/*
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'
*/

    export default {
        //mixins: [validationMixin],
        name: 'contexte',
        components: {
        },
        /*
        validations: {
            form: {
                code: { required, maxLength: maxLength(16)},
                libelle: { required, maxLength: maxLength(256) },
                remarques: {  required },
            }
        },
        */
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                client:null,
                clients:null,
                famille:null,
                familles:null,
                historiqueRecent:null,
                documentsRecents:null,
                projet:null,
                uc:null,
                sprint:null,
                pdf:null,
                editionEnCours:0,
                /*
                form: {
                    code: "",
                    libelle: "",
                    remarques:"",
                },
                error: {
                    code: "",
                    libelle: "",
                    remarques:"",
                },
                dialogError:false,
                errorDemandeMaj:false,
                messageMaj:"",
                */
                ready:false,
            }
        },


        watch: {
            famille(newValue) {
                var monContexte = new Object();
                monContexte.client =this.client;
                monContexte.famille = this.famille;
                monContexte.documentsRecents = this.documentsRecents;
                monContexte.historiqueRecent = this.historiqueRecent;
                this.$emit('retourContexte', monContexte);
            }

        },

        created: function () {

            addeoGet.lireClients().then(response => {
                if (response && response.success == true) {
                    this.clients = response.data;
                    if (this.clients.length == 1) {
                        this.client = this.clients[0];
                    }

                    addeoGet.lireFamilles().then(response => {
                        if (response && response.success == true) {
                            this.familles = response.data;
                            if (this.familles.length) { // TMP un client, une famille !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                                this.famille = this.familles[0];
                                this.ready = true;
                            }
  /*
                            if (this.familles.length == 1) {
                                const id = this.familles[0].id;
                                addeoPost.lireHistoriqueRecents(id).then(response => {
                                    if (response && response.retour && response.retour.success == true) {
                                        this.historiqueRecent = response.retour.data;
                                    }
                                    addeoPost.lireDocumentsRecents(id).then(response => {
                                    if (response && response.retour && response.retour.success == true) {
                                            this.documentsRecents = response.retour.data;
                                        }
                                        this.famille = this.familles[0];
                                        this.ready = true;
                                    })
                                })
                            }
*/
                        }
                    })

                }
            })

        },

        mounted: function () {
        },

        computed: {
            // pour les combos
            lesClients() {
                return(this.clients);
            },
            lesFamilles() {
                return(this.familles);
            },
        },

        methods: {
        }
    }
</script>

<style lang="scss">
$couleur-texte: #496785;
    #app {
        #contexte {
            background-color: #F2F2F2;
            padding-bottom: 1em;

            img.logo {
                max-width: 200px;
                height: auto;
                margin:1em 0;
            }
            .infosContexte {
                margin-left:2em;
                border-left: 8px #0075BE solid;
                padding-left: 1em;
                h1 {
                    color:#0075BE;
                }
                h2 {
                    color:$couleur-texte;
                    padding:0;
                    margin:0.4em 0 0;
                    background-color: transparent;
                }
            }

            form {
                width:100%;
                min-width:900px;
                padding:2em 0 0;

                > div:first-of-type {
                    margin-right:2em;
                }
            }
        }
    }



</style>
