<template>
    <div id="projets" class="">
        <div class="">
            <div v-if="ready">
                <table id="listeProjets" class="tableSTD" v-if="lesProjets && lesProjets.length">
                    <tr class="tris">
                        <th class="lien" @click.stop="trierCode">
                            <span>Code</span>
                            <i v-if="triEnCours != 'CODEDESC' && triEnCours != 'CODEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueEtat">
                            <span>Etat</span>
                            <i v-if="triEnCours != 'ORDREDESC' && triEnCours != 'ORDREASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueDate">
                            <span>Modification</span>
                            <i v-if="triEnCours != 'DATEDESC' && triEnCours != 'DATEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th colspan="5"></th>
                    </tr>

                    <tr class="itemProjet"  v-for="(leProjet, numProjet) in lesProjets" :id="numProjet" :key="numProjet" v-bind:class="{restituer:estEnRetard(leProjet)==-2, aujourdhui:estEnRetard(leProjet)==-1, retard:estEnRetard(leProjet)>0,   pair: Math.trunc(numProjet/2)*2==numProjet, ok: leProjet.UCs}"  >
                        <td class="libelle grand lien" @click.stop="chargerUCs(leProjet)" title="Accéder aux UCs du projet">
                            {{leProjet.code}} - {{leProjet.libelle}}
                        </td>
                        <td colspan="2" class="historique moyen lien" @mouseover="numActiveCourant = numProjet" @mouseleave="numActiveCourant = -1">
                            <span>
                                {{leProjet.historique[0].libelle}}
                            </span>
                            <div class="listeHistorique" v-if="numActiveCourant == numProjet"  >
                                <ul>
                                    <li class="itemHistorique" @click.stop="modifierHistorique(leHistorique, leProjet)"  v-for="(leHistorique, numHistorique) in leProjet.historique" title="Modifier l'élément de l'historique du projet">
                                        <span>
                                            {{leHistorique.libelle}}
                                        </span>
                                        &nbsp;
                                        <i class="fa fa-pencil svg" aria-hidden="true"></i>
                                    </li>
                                    <li class="itemHistorique nouvelEtat" @click.stop="modifierProjet(leProjet)" title="Ajouter un nouvel état au projet">
                                        <i class="fa fa-plus svg" aria-hidden="true"></i> Ajouter un nouvel Etat
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td class="lien ico" @click.stop="modifierProjet(leProjet)" title="Modifier le projet">
                            <i class="fa fa-pencil svg" aria-hidden="true"></i>
                        </td>
                        <td class="lien ico avecCompteur" @click.stop="editerPDFs(leProjet)" title="Accéder aux documents et liens du projet">
                            <i class="fa fa-folder-open svg floatLeft" aria-hidden="true"></i>
                            <span class="toutPetit floatLeft" v-if="leProjet.nbDocs">{{leProjet.nbDocs}}</span>
                        </td>
<!--
                        <td class="lien ico" @click.stop="depublierProjet(leProjet)">
-->
                        <td class="lien ico avecCompteur" @click.stop="chargerUCs(leProjet)" title="Accéder aux UCs du projet">
                            <i class="fa fa-list svg floatLeft" aria-hidden="true"></i>
                            <span class="toutPetit floatLeft" v-if="leProjet.nbUcs">{{leProjet.nbUcs}}</span>
                        </td>
                        <td class="lien ico poubelle" @click.stop="leProjetCourant=leProjet;confirmDialog2=true" title="Archiver le projet">
                            <i class="fa fa-archive svg" aria-hidden="true"></i>
                        </td>
                        <td class="lien ico poubelle" @click.stop="leProjetCourant=leProjet;confirmDialog=true" title="Dépublier le projet">
                            <i class="fa fa-trash svg" aria-hidden="true"></i>
                        </td>
                        <td v-if="0" class="remarque">{{leProjet.remarque}}</td>
                    </tr>
                </table>

                <div class="archives" v-if="lesProjetsArchives && lesProjetsArchives.length">
                    Archives
                    <i v-if="!blocVisible" class="fa fa-solid fa-caret-down lien" @click.stop="blocVisible=!blocVisible"></i>
                    <i v-if="blocVisible" class="fa fa-solid fa-caret-up lien" @click.stop="blocVisible=!blocVisible"></i>
                </div>

                <table id="listeProjetsArchives" class="tableSTD" v-if="blocVisible && lesProjetsArchives && lesProjetsArchives.length">


                    <tr class="itemProjet"  v-for="(leProjet, numProjet) in lesProjetsArchives" :id="numProjet" :key="numProjet" v-bind:class="{pair: Math.trunc(numProjet/2)*2==numProjet, ok: leProjet.UCs}"  >
                        <td class="libelle grand" >
                            {{leProjet.code}} - {{leProjet.libelle}}
                        </td>
                        <td colspan="2" class="historique moyen" >
                            <span>
                                {{leProjet.historique[0].libelle}}
                            </span>
                        </td>

                        <td class="lien ico poubelle" @click.stop="leProjetCourant=leProjet;confirmDialog3=true" title="Désarchiver le projet">
                            <i class="fa fa-refresh svg" aria-hidden="true"></i>
                        </td>
                        <td class="lien ico poubelle" @click.stop="leProjetCourant=leProjet;confirmDialog=true" title="Dépublier le projet">
                            <i class="fa fa-trash svg" aria-hidden="true"></i>
                        </td>
                    </tr>
                </table>

            </div>

        </div>


            <v-dialog v-model="confirmDialog" class="poubelle">
                    <v-card class="poubelle">
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous dépublier ce projet ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="abandonner">Non</v-btn>
                            <v-btn color="" text @click="depublier">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
            <v-dialog v-model="confirmDialog2" class="poubelle">
                    <v-card class="poubelle">
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous archiver ce projet ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="abandonner">Non</v-btn>
                            <v-btn color="" text @click="archiver">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
            <v-dialog v-model="confirmDialog3" class="poubelle">
                    <v-card class="poubelle">
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous désarchiver ce projet ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="abandonner">Non</v-btn>
                            <v-btn color="" text @click="desarchiver">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
/*
    import { ApiTools } from "../../js/api/index.js"
*/
/*
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'
*/

    export default {
        name: 'projets',
        components: {
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                laFamilleId:this.leContexte.famille.id,
                projets:null,
                projetsArchives:null,
                blocVisible:false,
                ready0:false,
                ready:false,
                numActiveCourant:-1,
                confirmDialog:false,
                confirmDialog2:false,
                confirmDialog3:false,
                leProjetCourant:null,
                triEnCours:"",
                myTimeout:null
            }
        },


        watch: {
            leContexte(newValue) {
                if (newValue) {
                    this.triEnCours = "";
                    this.ready = false;
                    this.ready0 = false;
                    this.laFamilleId = this.leContexte.famille.id,
                    this.lireProjets();
                }
            },

            ready0(newValue) {
                if (newValue) {
                    this.triEnCours = "";
                    this.trierCode();
                    this.ready = true;
                }
            },
            blocVisible(newValue) {
                if (newValue) {
                    this.myTimeout = setTimeout(this.scrollBottom, 100);
                }
            }
        },

        created: function () {
            if (this.laFamilleId) {
                this.lireProjets();
            }

        },

        mounted: function () {
        },

        computed: {
            lesProjets() {
                return(this.projets);
            },
            lesProjetsArchives() {
                return(this.projetsArchives);
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.projets);
                }
                return(null);
            },
            lesEtatsSprints () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.sprints);
                }
                return(null);
            },
            /*
            laFamilleId() {
                if (this.leContexte) {
                    return(this.leContexte.famille.id);
                }
                return(0);
            }
            */
        },

        methods: {
            scrollBottom() {
                if (this.myTimeout) {
                    clearTimeout(this.myTimeout);
                }
                window.scrollTo({
                    top: document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            estEnRetard(leProjet) {
                return(leProjet.enRetard);
            },
            // modification suite à demande Jacques du 18/01/2023
            trierCode() {
                //wiz
                if (this.triEnCours == "CODEDESC") {
                    this.triEnCours = "CODEASC";
                }
                else {
                    this.triEnCours = "CODEDESC";
                }
                this.projets.sort(this.triCode);
                this.projets = JSON.parse(JSON.stringify(this.projets));
                // idem pour archives
                this.projetsArchives.sort(this.triCode);
                this.projetsArchives = JSON.parse(JSON.stringify(this.projetsArchives));
            },
            triCode(x, y) {
                const anneeCodeX = x.code.substring(0, 4);
                const anneeCodeY = y.code.substring(0, 4);
                const suiteCodeX = x.code.substring(4);
                const suiteCodeY = y.code.substring(4);
                // on commence par trier par année
                if (this.triEnCours == "CODEASC") {
                    if (anneeCodeX < anneeCodeY) {
                        return(-1)
                     }
                    if (anneeCodeY < anneeCodeX) {
                        return(1)
                     }
                }
                else {
                    if (anneeCodeX < anneeCodeY) {
                        return(1)
                     }
                    if (anneeCodeY < anneeCodeX) { // tri descendant
                        return(-1)
                     }
                }
                // puis on tri par le reste mais toujours en "ascendant"
                if (suiteCodeX < suiteCodeY) {
                    return(-1)
                }
                return(1)
            },
            /*
            trierCode() {
                if (this.triEnCours == "CODEASC") {
                    this.triEnCours = "CODEDESC";
                }
                else {
                    this.triEnCours = "CODEASC";
                }
                this.projets.sort(this.triCode);
                this.projets = JSON.parse(JSON.stringify(this.projets));
            },
            triCode(x, y) {
                if (this.triEnCours == "CODEASC") {
                    if (x.code < y.code) {
                        return(-1)
                     }
                    if (y.code < x.code) {
                        return(1)
                     }
                }
                else {
                    if (x.code < y.code) {
                        return(1)
                     }
                    if (y.code < x.code) { // tri descendant
                        return(-1)
                     }
                }
            },
            */
            // FIN modification suite à demande Jacques du 18/01/2023
            trierHistoriqueEtat() {
                if (this.triEnCours == "ORDREASC") {
                    this.triEnCours = "ORDREDESC";
                }
                else {
                    this.triEnCours = "ORDREASC";
                }
                this.projets.sort(this.triOrdre);
                this.projets = JSON.parse(JSON.stringify(this.projets));
            },
            triOrdre(x, y) {
                if (this.triEnCours == "ORDREDESC") {
                    return(y.historique[0].ordre - x.historique[0].ordre); // tri ascendant
                }
                else {
                    return(x.historique[0].ordre - y.historique[0].ordre); // tri descendant
                }
            },
            trierHistoriqueDate() {
                if (this.triEnCours == "DATEASC") {
                    this.triEnCours = "DATEDESC";
                }
                else {
                    this.triEnCours = "DATEASC";
                }
                this.projets.sort(this.triDate);
                this.projets = JSON.parse(JSON.stringify(this.projets));
            },
            triDate(x, y) {
                if (this.triEnCours == "DATEASC") {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(-1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) {
                        return(1)
                     }
                }
                else {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) { // tri descendant
                        return(-1)
                     }
                }
            },

            modifierProjet(leProjet) {
                this.$emit('retourChoixProjet', leProjet);
            },
            editerPDFs(leProjet) {
                this.$emit('retourEditerPDFsProjet', leProjet);
            },
            abandonner() {
                this.confirmDialog = false;
                this.confirmDialog2 = false;
                this.confirmDialog3 = false;
            },
            depublier() {
                this.confirmDialog = false;
                this.depublierProjet();
            },
            archiver() {
                this.confirmDialog2 = false;
                this.archiverProjet();
            },
            desarchiver() {
                this.confirmDialog3 = false;
                this.desarchiverProjet();
            },
            depublierProjet() {
                if (this.leProjetCourant) {
                    addeoPost.depublierProjet(this.leProjetCourant.id).then(response => {
                        this.$emit('retourDepublierProjet', null);
                    })
                }
            },
            archiverProjet() {
                if (this.leProjetCourant) {
                    addeoPost.archiverProjet(this.leProjetCourant.id).then(response => {
                        this.$emit('retourDepublierProjet', null); // on utilise le même retour (pour recharger)
                    })
                }
            },
            desarchiverProjet() {
                if (this.leProjetCourant) {
                    addeoPost.desarchiverProjet(this.leProjetCourant.id).then(response => {
                        this.$emit('retourDepublierProjet', null); // on utilise le même retour (pour recharger)
                    })
                }
            },
            chargerUCs(leProjet) {
                this.$emit('retourChargerUCs', leProjet);
            },
            lireProjets() {
                var thisObj = this;
                var ladate=new Date();
                var dateDuJour = ladate.getFullYear()+"-"+(ladate.getMonth()+1)+"-"+ladate.getDate();

                addeoGet.lireProjets(thisObj.laFamilleId).then(response => {
                    thisObj.ucsTraiteesSprints = 0;
                    thisObj.projetsTraitables = 0;
                    thisObj.totalProjets = 0;
                    thisObj.totalUcs = 0;
                    if (response && response.retour && response.retour.success == true) {
                        const tabProjets = response.retour.data;
                        thisObj.projets = tabProjets.filter(projet => !projet.archive);
                        thisObj.projetsArchives = tabProjets.filter(projet => projet.archive);
                        thisObj.leContexte.projets = thisObj.projets;
                        thisObj.totalProjets = thisObj.projets.length;

                        // libelle états des historiques
                        thisObj.projets.forEach((projet, indexProjet) => {
                            var unHistorique = null;
                            projet.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                //historique.libelle = lEtat.libelle + " (" + lEtat.modified.substr(0, 10) +")";
                                historique.libelle = lEtat.libelle + " - " + historique.modified.substr(0, 10);
                                historique.libelleEtat = lEtat.libelle;
                                historique.ordre = lEtat.ordre;
                            })
                            thisObj.$parent.analyserRetardProjet(projet, dateDuJour, thisObj);
                        })
                        // libelle états des historiques des projets archivés
                        thisObj.projetsArchives.forEach((projet, indexProjet) => {
                            var unHistorique = null;
                            projet.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                historique.libelle = lEtat.libelle + " - " + historique.modified.substr(0, 10);
                                historique.libelleEtat = lEtat.libelle;
                                historique.ordre = lEtat.ordre;
                            })
                        })
                    }
                    if (!thisObj.projets || !thisObj.projets.length) {
                        thisObj.leContexte.projets = null;
                        thisObj.ready0 = true;
                    }
                })
            },
            modifierHistorique(leHistorique, leProjet) {
                leHistorique.codePere = leProjet.code;
                this.$emit('retourModifierHistorique', leHistorique);
            },
        }
    }
</script>

<style lang="scss">
    #app {
        #listeProjets, #listeProjetsArchives {
/*
            max-width: 1000px;
            min-width: 400px;
            width: 90%;
*/
            tr, td {
                position:relative;
            }
            tr {

                td, th {
    /*
                    padding:0.6em 1em;
                    margin:0.4em 1em;

                    &.grand {
                        min-width:200px;
                        width:60%;
                    }

                    &.moyen {
                        min-width:100px;
                        width:30%;
                    }

                    &.historique, &.deadline, &.ico  {
                        vertical-align: top;
                    }
    */
                    .v-select__slot {
                        background-color: transparent;
                        padding-top:0;

                        .v-select__selection {
                            color:#496785;
                        }
                        .v-icon {
                            color:#496785;
                        }
                    }
                    .v-text-field__details {
                        display:none;
                    }

                    .listeHistorique {
                        position:absolute;
                        z-index: 2;
                        left:0;
                        top:0;
                        background-color: #eee;
                        border:#bbb solid 1px;
                        padding:0.4em 2em;
                        width:100%;
                        min-height: 100%;
                        box-sizing: border-box;
                        >div {
                            margin-bottom: 0.2em;
                        }
                    }
                }
                &.retard > td {
                    background-color: #fde3b2;
                }
                &.aujourdhui > td {
                    background-color: #ffffc8;
                }
                &.restituer > td {
                    background-color: #d8e4fa;
                }
            }
        }

        #listeProjetsArchives {
            margin-bottom:2em;
        }
        .archives {
            border-radius: 6px 6px 0 0;
            background-color: #DDDDDD;
            margin:2em 0 0.6em;
            padding:0.6em;

            i {
                margin-left: 0.6em;
                margin-top: 0;
                vertical-align: top;
                font-size: 1.6em;
            }
        }
    }


</style>
