<template>
    <div id="sprints" class="">
        <div class="">
            <div v-if="ready">
                <span v-if="lesSprints && !lesSprints.length">Pas encore de sprint dans cette UC !</span>
                <table id="listeSprints" class="tableSTD" v-if="lesSprints && lesSprints.length">
                    <tr class="tris"  v-bind:class="{ sansTri: lesSprints.length == 1}">
                        <th colspan="5" class="lien colspan5"   @click.stop="trierCode">
                            <span>Libellé</span>
                            <span v-if="lesSprints.length > 1">
                                <br/>
                                <i v-if="triEnCours != 'CODEDESC' && triEnCours != 'CODEASC'" class="fa fa-sort" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'CODEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'CODEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueEtat">
                            <span>Etat</span>
                            <span v-if="lesSprints.length > 1">
                                <br/>
                                <i v-if="triEnCours != 'ORDREDESC' && triEnCours != 'ORDREASC'" class="fa fa-sort" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'ORDREDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'ORDREASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueDate">
                            <span>Début</span>
                            <span v-if="lesSprints.length > 1">
                                <br/>
                                <i v-if="triEnCours != 'DATEDESC' && triEnCours != 'DATEASC'" class="fa fa-sort" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'DATEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'DATEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th colspan="2" class="colspan2 lien" @click.stop="trierDeadline">
                            <span>Deadline</span>
                            <span v-if="lesSprints.length > 1">
                                <br/>
                                <i v-if="triEnCours != 'DEADDESC' && triEnCours != 'DEADASC'" class="fa fa-sort" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'DEADDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                                <i v-if="triEnCours == 'DEADASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                            </span>
                        </th>
                    </tr>
                    <tr class="itemSprint"  v-for="(leSprint, numSprint) in lesSprints" :id="numSprint" :key="numSprint" v-bind:class="{ pair: Math.trunc(numSprint/2)*2==numSprint}"  >


                        <td colspan="10" class="colspanGroup colspanGroup10">

                            <table class="tableSTD">
                                <tr>
                                    <td v-if="vueInfos != leSprint.id" class="libelle grand">
                                        {{leSprint.code}} - {{leSprint.libelle}}
                                        <span @click.stop="voirInfos(leSprint)" title="Obtenir la description et la documentation du sprint" class="lien noFloat zoneClient ">
                                            &nbsp;-&nbsp;
                                            <span class="noFloat">
                                                <span class="noFloat noRC">
                                                    (voir)
                                                    <span class="noFloat" v-if="leSprint.nbDocs">
                                                        {{leSprint.nbDocs}}&nbsp;<i class="fa fa-file svg petit" aria-hidden="true"></i>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                    <td v-if="vueInfos == leSprint.id" class="libelle grand">
                                        {{leSprint.code}} - {{leSprint.libelle}}
                                        <span @click.stop="voirInfos(leSprint)" title="Refermer la description et la documentation du sprint" class="lien noFloat zoneClient ">
                                            &nbsp;-&nbsp;
                                            <span class="noFloat">
                                                (refermer)
                                            </span>
                                        </span>
                                    </td>

                                    <td class="historique moyen lien" @mouseover="numActiveCourant = numSprint" @mouseleave="numActiveCourant = -1">
                                        <span>
                                            {{leSprint.historique[0].libelleEtat}}
                                        </span>
                                        au {{leSprint.historique[0].dateAffichee}}
                                        <div class="listeHistorique" v-if="numActiveCourant == numSprint"  >
                                            <div class="itemHistorique" v-for="(leHistorique, numHistorique) in leSprint.historique">
                                                <span>
                                                    {{leHistorique.libelleEtat}}
                                                </span>
                                                au {{leHistorique.dateAffichee}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="deadline petit" >
                                        {{leSprint.deadline}}
                                    </td>
                                </tr>


<transition name="slide-fade" >
                                <tr v-if="leContexte.sprint && (vueInfos == leSprint.id)" class="infosDocs">
                                    <td colspan="4" class="colspanGroup">
                                        <InfosDocs :leContexte="leContexteSprint"  />
                                    </td>
                                </tr>
</transition>

                            </table>

                        </td>

                    </tr>
                </table>

            </div>

        </div>

    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
    import InfosDocs from '@/components/front/InfosDocs.vue'

    export default {
        name: 'sprints',
        components: {
            InfosDocs,
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                laUcId:this.leContexte.uc.id,
                sprints:null,
                ready:false,
                //tabHistoriquesModels:[],
                numActiveCourant:-1,
                triEnCours:"",
                confirmDialog:false,
                leSprintCourant:null,
                vueInfos:false,
            }
        },


        watch: {
            leContexte(newValue) {
                if (newValue) {
                    this.laUcId = this.leContexte.uc.id,
                    this.lireSprints();
                }
            },
        },

        created: function () {
            if (this.laUcId) {
                this.lireSprints();
            }

        },

        mounted: function () {
        },

        computed: {
            lesSprints() {
                return(this.sprints);
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.sprints);
                }
                if (this.$parent.$parent.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.$parent.$parent.objEtats.sprints);
                }
                return(null);
            },
            leContexteSprint() {
                var contexteSprint = JSON.parse(JSON.stringify(this.leContexte));
                return(contexteSprint)
            }
        },

        methods: {
            trierCode() {
                if (this.triEnCours == "CODEASC") {
                    this.triEnCours = "CODEDESC";
                }
                else {
                    this.triEnCours = "CODEASC";
                }
                this.sprints.sort(this.triCode);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triCode(x, y) {
                if (this.triEnCours == "CODEASC") {
                    if (x.code < y.code) {
                        return(-1)
                     }
                    if (y.code < x.code) {
                        return(1)
                     }
                }
                else {
                    if (x.code < y.code) {
                        return(1)
                     }
                    if (y.code < x.code) { // tri descendant
                        return(-1)
                     }
                }
            },
            trierHistoriqueEtat() {
                if (this.triEnCours == "ORDREASC") {
                    this.triEnCours = "ORDREDESC";
                }
                else {
                    this.triEnCours = "ORDREASC";
                }
                this.sprints.sort(this.triOrdre);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triOrdre(x, y) {
                if (this.triEnCours == "ORDREDESC") {
                    return(y.historique[0].ordre - x.historique[0].ordre); // tri ascendant
                }
                else {
                    return(x.historique[0].ordre - y.historique[0].ordre); // tri descendant
                }
            },
            trierHistoriqueDate() {
                if (this.triEnCours == "DATEASC") {
                    this.triEnCours = "DATEDESC";
                }
                else {
                    this.triEnCours = "DATEASC";
                }
                this.sprints.sort(this.triDate);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triDate(x, y) {
                if (this.triEnCours == "DATEASC") {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(-1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) {
                        return(1)
                     }
                }
                else {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) { // tri descendant
                        return(-1)
                     }
                }
            },
            trierDeadline() {
                if (this.triEnCours == "DEADASC") {
                    this.triEnCours = "DEADDESC";
                }
                else {
                    this.triEnCours = "DEADASC";
                }
                this.sprints.sort(this.triDeadline);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triDeadline(x, y) {
                if (this.triEnCours == "DEADASC") {
                    if (x.deadline < y.deadline) {
                        return(-1)
                     }
                    if (y.deadline < x.deadline) {
                        return(1)
                     }
                }
                else {
                    if (x.deadline < y.deadline) {
                        return(1)
                     }
                    if (y.deadline < x.deadline) { // tri descendant
                        return(-1)
                     }
                }
            },
            voirInfos(leSprint) {
                if (this.vueInfos == leSprint.id) {
                    this.vueInfos = false;
                }
                else {
                    this.vueInfos = leSprint.id;
                    this.leContexte.sprint = leSprint;
                }
            },
            lireSprints() {
                var thisObj = this;
                addeoGet.lireSprints(thisObj.laUcId).then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.sprints = response.retour.data;

                        // libelle états des historiques
                        thisObj.sprints.forEach((sprint, indexProjet) => {
                            var unHistorique = null;
                            sprint.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                historique.libelle = lEtat.libelle + "&nbsp;au&nbsp;" + historique.modified.substr(0, 10);
                                historique.libelleEtat = lEtat.libelle;
                                historique.libelleDate = historique.modified.substr(0, 10);
                                historique.dateAffichee = this.traiterDate(historique.libelleDate);
                                historique.ordre = lEtat.ordre;
                            })
                        })

                        this.triEnCours = "";
                        this.trierCode();
                        thisObj.ready = true;
                    }
                })
            },
            traiterDate(datePasAffichee) {
                var tab = datePasAffichee.split('-')
                var dateAffichee = tab[2]+"-"+tab[1]+"-"+tab[0];
                return(dateAffichee);
            },
        }
    }
</script>

<style lang="scss">
    #app {
        #front {
            #listeProjets {
                #listeUcs {
                    #listeSprints {
                        margin-top:1em;
                        font-size:95%;
                        .tris {
                            th {
                                width:10%;
                                &.colspan5 {
                                    /*width:56%;*/
                                    width:60%;
                                }
                                /*
                                &.colspan3 {
                                    width:12%;
                                }
                                */
                                &.colspan2 {
                                    width:14%;
                                }
                            }
                        }
                        tr, td {
                            position:relative;
                        }
                        td {

                            &.grand {
                                min-width:480px;
                                width:60%;
                            }

                            &.moyen {
                                min-width:100px;
                                width:28%;
                            }
                            &.petit {
                                min-width:80px;
                                width:12%;
                            }

                            &.historique {
                                /*vertical-align: top;*/
                            }

                            .v-select__slot {
                                background-color: transparent;
                                padding-top:0;

                                .v-select__selection {
                                    color:#496785;
                                }
                                .v-icon {
                                    color:#496785;
                                }
                            }
                            .v-text-field__details {
                                display:none;
                            }

                            .listeHistorique {
                                position:absolute;
                                z-index: 2;
                                left:0;
                                top:0;
                                background-color: #eee;
                                border:#bbb solid 1px;
                                padding:0.4em 2em;
                                width:100%;
                                min-height: 100%;
                                padding:0.4em 1em;
                                box-sizing: border-box;
                                >div {
                                    margin-bottom: 0.2em;
                                }
                            }
                        }
                    }

                }
            }
        }

    }



</style>
