<template>
    <div id="sprints" class="contenu">
        <div class="">
            <div v-if="ready">
                <table id="listeSprints" class="tableSTD" v-if="lesSprints && lesSprints.length">
                    <tr class="tris">
                        <th class="lien" @click.stop="trierCode">
                            <span>Code</span>
                            <i v-if="triEnCours != 'CODEDESC' && triEnCours != 'CODEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueEtat">
                            <span>Etat</span>
                            <i v-if="triEnCours != 'ORDREDESC' && triEnCours != 'ORDREASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueDate">
                            <span>Modification</span>
                            <i v-if="triEnCours != 'DATEDESC' && triEnCours != 'DATEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierDeadline">
                            <span>Deadline</span>
                            <i v-if="triEnCours != 'DEADDESC' && triEnCours != 'DEADASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DEADDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DEADASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th colspan="2"></th>
                    </tr>
                    <tr class="itemSprint"  v-for="(leSprint, numSprint) in lesSprints" :id="numSprint" :key="numSprint" v-bind:class="{restituer:estEnRetard(leSprint)==-2, aujourdhui:estEnRetard(leSprint)==-1, retard:estEnRetard(leSprint)>0, pair: Math.trunc(numSprint/2)*2==numSprint}"  >
                        <td class="libelle grand " >
                            {{leSprint.code}} - {{leSprint.libelle}}
                        </td>
                        <td colspan="2" class="historique moyen lien" @mouseover="numActiveCourant = numSprint" @mouseleave="numActiveCourant = -1">
                            <span>
                                {{leSprint.historique[0].libelle}}
                            </span>
                            <div class="listeHistorique" v-if="numActiveCourant == numSprint"  >
                                <div class="itemHistorique" @click.stop="modifierHistorique(leHistorique, leSprint)" v-for="(leHistorique, numHistorique) in leSprint.historique" title="Modifier l'élément de l'historique du sprint">
                                    <span>
                                        {{leHistorique.libelle}}
                                    </span>
                                    &nbsp;<i class="fa fa-pencil svg" aria-hidden="true"></i>
                                </div>
                                <div class="itemHistorique nouvelEtat" @click.stop="modifierSprint(leSprint)" title="Ajouter un nouvel état au Sprint">
                                    <i class="fa fa-plus svg" aria-hidden="true"></i> Ajouter un nouvel Etat
                                </div>
                            </div>
                        </td>
                        <td class="deadline moyenDEL" >
                            {{leSprint.deadline}}
                        </td>
                        <td class="lien ico" @click.stop="modifierSprint(leSprint)" title="Modifier le sprint">
                            <i class="fa fa-pencil svg" aria-hidden="true"></i>
                        </td>
                        <td class="lien ico avecCompteur" @click.stop="editerPDFs(leSprint)" title="Accéder aux documents et liens du sprint">
                            <i class="fa fa-folder-open svg floatLeft" aria-hidden="true"></i>
                            <span class="toutPetit floatLeft" v-if="leSprint.nbDocs">{{leSprint.nbDocs}}</span>
                        </td>
                        <td class="lien ico poubelle" @click.stop="leSprintCourant=leSprint;confirmDialog=true" title="Dépublier le sprint">
                            <i class="fa fa-trash svg" aria-hidden="true"></i>
                        </td>
                        <td v-if="0" class="remarque">{{leSprint.remarque}}</td>
                    </tr>
                </table>

            </div>

        </div>

            <v-dialog v-model="confirmDialog" class="poubelle">
                    <v-card class="poubelle">
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous dépublier ce Sprint ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="abandonner">Non</v-btn>
                            <v-btn color="" text @click="depublier">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
/*
    import { ApiTools } from "../../js/api/index.js"
*/
/*
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'
*/

    export default {
        name: 'sprints',
        components: {
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                laUcId:this.leContexte.uc.id,
                sprints:null,
                ready:false,
                //tabHistoriquesModels:[],
                numActiveCourant:-1,
                triEnCours:"",
                confirmDialog:false,
                leSprintCourant:null,
            }
        },


        watch: {
            leContexte(newValue) {
                if (newValue) {
                    this.laUcId = this.leContexte.uc.id,
                    this.lireSprints();
                }
            },
        },

        created: function () {
            if (this.laUcId) {
                this.lireSprints();
            }

        },

        mounted: function () {
        },

        computed: {
            lesSprints() {
                return(this.sprints);
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.sprints);
                }
                return(null);
            },
        },

        methods: {
            estEnRetard(leSprint) {
                return(leSprint.enRetard);
            },
            trierCode() {
                if (this.triEnCours == "CODEASC") {
                    this.triEnCours = "CODEDESC";
                }
                else {
                    this.triEnCours = "CODEASC";
                }
                this.sprints.sort(this.triCode);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triCode(x, y) {
                if (this.triEnCours == "CODEASC") {
                    if (x.code < y.code) {
                        return(-1)
                     }
                    if (y.code < x.code) {
                        return(1)
                     }
                }
                else {
                    if (x.code < y.code) {
                        return(1)
                     }
                    if (y.code < x.code) { // tri descendant
                        return(-1)
                     }
                }
            },
            trierHistoriqueEtat() {
                if (this.triEnCours == "ORDREASC") {
                    this.triEnCours = "ORDREDESC";
                }
                else {
                    this.triEnCours = "ORDREASC";
                }
                this.sprints.sort(this.triOrdre);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triOrdre(x, y) {
                if (this.triEnCours == "ORDREDESC") {
                    return(y.historique[0].ordre - x.historique[0].ordre); // tri ascendant
                }
                else {
                    return(x.historique[0].ordre - y.historique[0].ordre); // tri descendant
                }
            },
            trierHistoriqueDate() {
                if (this.triEnCours == "DATEASC") {
                    this.triEnCours = "DATEDESC";
                }
                else {
                    this.triEnCours = "DATEASC";
                }
                this.sprints.sort(this.triDate);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triDate(x, y) {
                if (this.triEnCours == "DATEASC") {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(-1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) {
                        return(1)
                     }
                }
                else {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) { // tri descendant
                        return(-1)
                     }
                }
            },
            trierDeadline() {
                if (this.triEnCours == "DEADASC") {
                    this.triEnCours = "DEADDESC";
                }
                else {
                    this.triEnCours = "DEADASC";
                }
                this.sprints.sort(this.triDeadline);
                this.sprints = JSON.parse(JSON.stringify(this.sprints));
            },
            triDeadline(x, y) {
                if (this.triEnCours == "DEADASC") {
                    if (x.deadline < y.deadline) {
                        return(-1)
                     }
                    if (y.deadline < x.deadline) {
                        return(1)
                     }
                }
                else {
                    if (x.deadline < y.deadline) {
                        return(1)
                     }
                    if (y.deadline < x.deadline) { // tri descendant
                        return(-1)
                     }
                }
            },
            modifierSprint(leSprint) {
                this.$emit('retourChoixSprint', leSprint);
            },
            editerPDFs(leSprint) {
                this.$emit('retourEditerPDFsSprint', leSprint);
            },
            abandonner() {
                this.confirmDialog = false;
            },
            depublier() {
                this.confirmDialog = false;
                this.depublierSprint();
            },
            depublierSprint() {
                if (this.leSprintCourant) {
                    addeoPost.depublierSprint(this.leSprintCourant.id).then(response => {
                        this.$emit('retourDepublierSprint', null);
                    })
                }
            },
            lireSprints() {
                var thisObj = this;
                addeoGet.lireSprints(thisObj.laUcId).then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.sprints = response.retour.data;
                        this.$emit('retourListeSprints', thisObj.sprints);

                        // libelle états des historiques
                        thisObj.sprints.forEach((sprint, indexSprint) => {
                            var unHistorique = null;
                            sprint.enRetard = false;
                            sprint.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                historique.libelle = lEtat.libelle + " - " + historique.modified.substr(0, 10);
                                historique.libelleEtat = lEtat.libelle;
                                historique.ordre = lEtat.ordre;
                                if (!index) {
                                    if (!lEtat.fini && sprint.deadline) {
                                        // si pas fini, on compare Deadline et date du jour
                                        var ladate=new Date();
                                        var dateDuJour = ladate.getFullYear()+"-"+(ladate.getMonth()+1)+"-"+ladate.getDate();
                                        //if (dateDuJour > sprint.deadline) {
                                        if (thisObj.$parent.diffJours(dateDuJour, sprint.deadline) < 0) {
                                            sprint.enRetard = 1;
                                        }
                                        else {
                                            if (thisObj.$parent.diffJours(dateDuJour, sprint.deadline) < 7) {
                                            //if (sprint == sprint.deadline) {
                                                sprint.enRetard = -1;
                                            }
                                        }
                                    }
                                    else {
                                        if (lEtat.code == "SARES") {
                                            sprint.enRetard = -2; // a restituer en vert !!!
                                        }
                                    }
                                }
                            })
                        })

                        this.triEnCours = "";
                        this.trierCode();
                        thisObj.ready = true;
                    }
                })
            },
            modifierHistorique(leHistorique, leSprint) {
                leHistorique.codePere = leSprint.code;
                this.$emit('retourModifierHistorique', leHistorique);
            }
        }
    }
</script>

<style lang="scss">
    #app {
        #back {
            #listeSprints {
    /*
                max-width: 1000px;
                min-width: 400px;
                width: 90%;
    */
                tr, td {
                    position:relative;
                }
                tr {
                    td {
                        &.grand {
                            width:55%;
                        }
        /*
                        padding:0.6em 1em;
                        margin:0.4em 1em;

                        &.grand {
                            min-width:480px;
                            width:60%;
                        }

                        &.moyen {
                            min-width:120px;
                            width:30%;
                        }

                        &.historique, &.deadline, &.ico  {
                            vertical-align: top;
                        }
        */
                        .v-select__slot {
                            background-color: transparent;
                            padding-top:0;

                            .v-select__selection {
                                color:#496785;
                            }
                            .v-icon {
                                color:#496785;
                            }
                        }
                        .v-text-field__details {
                            display:none;
                        }

                        .listeHistorique {
                            position:absolute;
                            z-index: 2;
                            left:0;
                            top:0;
                            background-color: #eee;
                            border:#bbb solid 1px;
                            padding:0.4em 2em;
                            width:100%;
                            min-height: 100%;
                            padding:0.4em 1em;
                            box-sizing: border-box;
                            >div {
                                margin-bottom: 0.2em;
                            }
                        }
                    }
                    &.retard > td {
                        background-color: #fde3b2;
                    }
                    &.aujourdhui > td {
                        background-color: #ffffc8;
                    }
                    &.restituer > td {
                        background-color: #d8e4fa;
                    }
                }
            }

        }

    }



</style>
