<template>
  <div class="container" id="front">

    <Contexte :leContexte="contexte" v-if="1 || (!contexte || (!contexte.editionEnCours && !contexte.projet))" @retourContexte="onRetourContexte" />

    <div class="contenu" v-if="ready && readyProjet">
        <Historique :leContexte="contexte" :back=0 @retourJournal="onRetourJournal" />
    </div>

    <div class="contenu" v-if="ready && readyProjet">
        <h2 class="projets"><span>Projets</span></h2>
    </div>
    <div v-if="readyProjet"  class="containerProjets">
<!--
        <h2>{{contexte.client.libelle}} /  {{contexte.famille.libelle}}</h2>
-->
        <Projets :leContexte="contexte"  />

    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Contexte from '@/components/front/FContexte.vue'
import Projets from '@/components/front/FProjets.vue'
//import Sprints from '@/components/front/FSprints.vue'
import Historique from '@/components/Historique.vue'

export default {
      name: 'Front',
      components: {
        Contexte,
        Projets,
        //Sprints,
        Historique
      },

    data() {
        return {
            contexte:null,
        }
    },

    created: function () {
        console.log("front : created");
    },

    computed: {
        ready() {
            return(this.$parent.ready);
        },

        readyProjet() {
            return(this.ready && this.contexte);
        },


        readySprint() {
            return(this.ready && this.contexte && this.contexte.projet && this.contexte.uc && !this.contexte.sprint);
        },
        readyHistorique() {
            return(this.ready && this.contexte && this.contexte.historique);
        },
    },

    methods: {
/*
        reinitialiserContexte(editerPDFs=false) {
            this.contexte.editerPDFsProjet = editerPDFs;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onEditionEnCours(editionEnCours) {
            this.contexte.editionEnCours = editionEnCours;
            this.reinitialiserContexte(this.contexte.editerPDFsProjet);
        },
*/
        onRetourContexte(contexte) {
            this.contexte = contexte;
            this.contexte = JSON.parse(JSON.stringify(this.contexte));
        },
        onRetourJournal(parent) {
            /*
            if (parent.const_etat_code) {
                if (parent.projet_id) {
                    this.onRetourChargerUCs(parent.structure);
                }
                if (parent.uc_id) {
                    this.onRetourChargerSprints(parent.structure);
                }
            }
            else {
                if (parent.projet_id) {
                    this.onRetourEditerPDFsProjet(parent.structure);
                }
                if (parent.uc_id) {
                    this.onRetourEditerPDFsUc(parent.structure);
                }
            }
            */
        }
    }
}

</script>

<style lang="scss">

$couleur-fond: #496785;
$couleur-texte: #496785;
/*$couleur-lien: #6588AB;*/
$couleur-lien: #004a71;
/*$couleur-hover: #05621e;*/
$couleur-hover: #0583c5;

    #app {
        .v-dialog {
            max-width: 600px;
        }

        .avantTexte {
            margin-right:1em;
            font-size:1.4em;
        }

        .container {

            &#front {

        /* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  /*transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);*/
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-40px);
  opacity: 0;
}

                max-width: 1200px;
                text-align: center;
                .contenu {
                    /*padding:2em 0 1em;*/
                    max-width: 1200px;
                    margin: 0 auto;
                    text-align: left;
                    .edition {
                        width: 100%;
                        max-width: 405px;
                    }

                    .aGauche {
                        float:left;
                    }
                    .clear {
                        clear:both;
                    }
                    H2.projets {
                        margin:1em 0 0.2em;
                        background-color: #F2F2F2;
                        color: #2c3e50;
                        span {
                            opacity:0.6;
                        }
                    }

                    H4, H5, H6 {
                        span {
                            opacity:1;
                            &.sousTitre > span {
                                color:#7B8691;
                            }
                        }
                    }
                }
                /*
                H2 {
                    text-align: left;
                    margin:2em 0 1em;
                    color:white;
                    background-color: $couleur-fond;
                    padding : 0.4em 1em;
                }
                H3 {
                    text-align: left;
                    margin:2em 0 1em;
                    color:white;
                    background-color: $couleur-fond;
                    padding : 0.4em 1em;
                    opacity: 0.7;
                }
                */
                .tris {
                    text-align: center;
                }
                .lien {
                    cursor:pointer;
                    color:$couleur-lien;
                    &:hover {
                        color:$couleur-hover;
                        a {
                            color:#999999;
                        }
                    }
                    a {
                        color:white;
                        text-decoration:none;
                        &:visited {
                            color:white;
                        }
                        &:hover {
                            color:#999999;
                        }
                    }
                }
                .libelle {
                    /*padding-left:50px;*/
                    span {
                        padding-left:10px;
                        float:left;
                    }
                }
                .zoneClient {
                    color:#ab17db;
                        &:hover {
                            color:$couleur-hover;
                        }
                    &i {
                        color:#004a71;
                    }
                }
                i.zoneClient {
                    color:#004a71;
                }
                .clair {
                    opacity: 0.5;
                }
                .nbDocs {
                    color:#555;
                }
                .decalage {
                    margin-left:-40px;
                    float:left;
                    vertical-align: middle;
                    padding-left:0 !important;
                    /*
                    top:50%;
                    position:absolute;
                    &:before {
                        position:absolute;
                        top:-0.75em;
                    }
                    */
                }
                .aGauche {
                    float:left;
                }
                .clear {
                    clear:both;
                }

                .gauche {
                    text-align: left;
                }
                .droite {
                    text-align: right;
                }
                .aDroite {
                    float:right;
                }
                .noFloat {
                    float:none !important;
                    margin:0 !important;
                    padding:0 !important;
                }
                .noRC {
                    white-space: nowrap;
                }

                .theme--light.v-icon {
                    color:white;
                }

                .champInactif {
                    margin:1em 0;
                    padding:23px 0.4em 15px;
                    background-color: #8095AA;
                    border-radius: 5px 5px 0 0;
                    color:white;
                    font-weight: bold;
                    border-bottom: 15px solid #EDEDED;
                }

                .boutonsValidation {
                    margin: 2em 0;
                    button {
                        margin-right:1em;
                    }
                }


                span.checkbox {
                    div {
                        display: inline;
                    }
                    .v-input {
                        background-color: transparent;

                        .v-input__control {
                            height:20px;
                            .v-label {
                                color: $couleur-texte;
                                padding:0 0 0 10px;
                            }
                        }
                    }
                    .v-icon {
                        color: $couleur-fond;
                        width:auto;
                    }
                }

                .nbItems {
                    min-width: 75px;
                }

                .historique {
                    min-width: 255px;
                    padding-left: 5px;
                    padding-right: 5px;
                    div.historiqueGauche, div.historiqueDroite {
                        display: inline-block;
                        float:left;
                    }
                    .historiqueGauche {
                        text-align: center;
                        width: 130px;
                        padding-right:5px;
                    }
                    .historiqueDroite {
                        width: 110px;
                        text-align: left;
                    }
                    .listeHistorique {
                        .itemHistorique {
                            &:hover {
                                color:white;
                            }
                        }
                    }
                }

                table {
                    width: 100%;
                    max-width: 100%;

                    td {
                        padding: 0.6em 0.8em;

                        .listeHistorique {
                            /*background-color: #5f4f3a !important;*/
                            background-color: #999 !important;
                            .itemHistorique {
                                color:white;
                                font-size:0.9em;
                                font-weight: normal;
                                cursor:default;
                                &:hover {
                                    color:white;
                                    /*color:#eee;*/
                                }
                            }
                        }
                    }
                    table {
                        width: 100%;

                        #listePdfs {
                            width: 100%;
                            td {
                                &.lien {
                                    color:#ab17db;
                                    a {
                                        color:#ab17db;
                                    }
                                }
                            }
                        }
                    }
                    #infosDocs, #listeUcs {
                        background-color: rgba(255, 255, 255, 0.1);
                        padding:1em;

                        #infosDocs, #listeSprints {
                            background-color: rgba(255, 255, 255, 0.1);
                            padding:1em;
                        }
                    }
                    #infosDocs {
                        /*background-color: white;*/
                        /* border-left: 4px solid #F2F2F2; */

                        .lien {
                            &:hover {
                                color:$couleur-hover;
                            }
                            a {
                                color:$couleur-lien;
                                &:hover {
                                    color:$couleur-hover;
                                }
                            }
                        }
                        table.tableSTD {
                            /*border-left: 4px solid #F2F2F2;*/
                            tr > td {
                                background-color: rgba(255, 255, 255, 0.4);
                            }
                        }
                    }

                    tr {
                        &.seul {
                            > td:first-of-type {
                                padding-top:1em;
                                > table {
                                    > tr:first-of-type {
                                        > td {
                                            /*background-color: rgba(0, 74, 113, 0.3);*/
                                            /*font-size:1.2em;*/
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                        }
                    }
/*
                    td.grand {
                        width:66%;
                    }

                    td.moyen {
                        width:26%;
                    }
*/
                    td.ico, th.ico {
                        min-width:30px;
                        max-width:30px;
                        width:30px;
                    }
                    td.grand {
                        min-width:600px;
                        width:62%;
                    }
                    td.moyen {
                        min-width:100px;
                        width:26%;
                    }
                    td.petit {
                        min-width:80px;
                        width:16%;
                    }

                    td.colspanGroup {
                        background-color: transparent !important;
                        padding-top: 0.2em;
                        padding-bottom: 0;
                        padding-left: 1em;
                        /*padding-right: 1em;*/
                        padding-right: 0;
                    }
                    td.deadline {
                        text-align: center;
                    }
                    table {
                        tr {
                            td.colspanGroup:not(.colspanGroup10) {
                                border:2px solid #F2F2F2;
                            }
                        }
                        tr:not(.infosDocs) {
                            td.colspanGroup:not(.colspanGroup10) {
                                /*border-top:1px solid #F2F2F2;*/
                            }
                        }
                        tr.infosDocs {
                            td.colspanGroup:not(.colspanGroup10) {
                               /*border-bottom:1px solid #F2F2F2;*/
                            }
                        }
                    }
                    tr.seul > td.colspanGroup > table.tableSTD {
                        margin-bottom: 2em;
                        /*border-bottom: 2px solid #F2F2F2;*/
                        /*border-left: 2px solid #F2F2F2;*/
                        tr:last-of-type > td {
                            padding-bottom: 1em;
                        }
                    > tr.infosDocs {
                        /*background-color: rgba(0, 74, 113, 0.1);*/
                        background-color: white;
                    }
                    }

                    span.plusPetit {
                        font-size:80%;
                    }
                    tr.sansTri {
                        th {
                            span {
                                margin-right:0;
                            }
                        }
                    }

                }

            }

        }
    }

</style>
