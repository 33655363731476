<template>
    <div id="ucs" class="contenu">
        <div class="">
            <div v-if="ready">
                <table id="listeUcs" class="tableSTD" v-if="lesUcs && lesUcs.length">
                    <tr class="tris">
                        <th class="lien" @click.stop="trierCode">
                            <span>Code</span>
                            <i v-if="triEnCours != 'CODEDESC' && triEnCours != 'CODEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueEtat">
                            <span>Etat</span>
                            <i v-if="triEnCours != 'ORDREDESC' && triEnCours != 'ORDREASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueDate">
                            <span>Modification</span>
                            <i v-if="triEnCours != 'DATEDESC' && triEnCours != 'DATEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th colspan="3"></th>
                    </tr>
                    <tr class="itemUc"  v-for="(laUc, numUc) in lesUcs" :id="numUc" :key="numUc" v-bind:class="{restituer:estEnRetard(laUc)==-2, aujourdhui:estEnRetard(laUc)==-1, retard:estEnRetard(laUc)>0,  pair: Math.trunc(numUc/2)*2==numUc}"  >
                        <td class="libelle grand lien" @click.stop="chargerSprints(laUc)" title="Accéder aux sprints de l'UC">
                            {{laUc.code}} - {{laUc.libelle}}
                        </td>
                        <td colspan="2" class="historique moyen lien" @mouseover="numActiveCourant = numUc" @mouseleave="numActiveCourant = -1">
                            <span>
                                {{laUc.historique[0].libelle}}
                            </span>
                            <div class="listeHistorique" v-if="numActiveCourant == numUc"  >
                                <div class="itemHistorique" @click.stop="modifierHistorique(leHistorique, laUc)"  v-for="(leHistorique, numHistorique) in laUc.historique" title="Modifier l'élément de l'historique de L'UC">
                                    <span>
                                        {{leHistorique.libelle}}
                                    </span>
                                    &nbsp;<i class="fa fa-pencil svg" aria-hidden="true"></i>
                                </div>
                                <div class="itemHistorique nouvelEtat" @click.stop="modifierUc(laUc)" title="Ajouter un nouvel état à l'UC">
                                    <i class="fa fa-plus svg" aria-hidden="true"></i> Ajouter un nouvel Etat
                                </div>
                            </div>
                        </td>
                        <td class="lien ico" @click.stop="modifierUc(laUc)" title="Modifier l'UC">
                            <i class="fa fa-pencil svg" aria-hidden="true"></i>
                        </td>
                        <td class="lien ico avecCompteur" @click.stop="editerPDFs(laUc)" title="Accéder aux documents et liens de l'UC">
                            <i class="fa fa-folder-open svg floatLeft" aria-hidden="true"></i>
                            <span class="toutPetit floatLeft" v-if="laUc.nbDocs">{{laUc.nbDocs}}</span>
                        </td>
                        <td class="lien ico avecCompteur" @click.stop="chargerSprints(laUc)" title="Accéder aux sprints de l'UC">
                            <i class="fa fa-list svg floatLeft" aria-hidden="true"></i>
                            <span class="toutPetit floatLeft" v-if="laUc.nbSprints">{{laUc.nbSprints}}</span>
                        </td>
                        <td class="lien ico poubelle" @click.stop="laUcCourante=laUc;confirmDialog=true" title="Dépublier l'UC">
                            <i class="fa fa-trash svg" aria-hidden="true"></i>
                        </td>
                        <td v-if="0" class="remarque">{{laUc.remarque}}</td>
                    </tr>
                </table>

            </div>

        </div>

            <v-dialog v-model="confirmDialog" class="poubelle">
                    <v-card class="poubelle">
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous dépublier cet UC ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="abandonner">Non</v-btn>
                            <v-btn color="" text @click="depublier">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
/*
    import { ApiTools } from "../../js/api/index.js"
*/
/*
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'
*/

    export default {
        name: 'ucs',
        components: {
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                leProjetId:this.leContexte.projet.id,
                ucs:null,
                ready0:false,
                ready:false,
                //tabHistoriquesModels:[],
                numActiveCourant:-1,
                triEnCours:"",
                confirmDialog:false,
                laUcCourante:null,
            }
        },


        watch: {
            leContexte(newValue) {
                if (newValue) {
                    this.leProjetId = this.leContexte.projet.id,
                    this.lireUcs();
                }
            },

            ready0(newValue) {
                if (newValue) {
                    this.triEnCours = "";
                    this.trierCode();
                    this.ready = true;
                }
            }
        },

        created: function () {
            if (this.leProjetId) {
                this.lireUcs();
            }

        },

        mounted: function () {
        },

        computed: {
            lesUcs() {
                return(this.ucs);
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.ucs);
                }
                return(null);
            },
            lesEtatsSprints () {
                if (this.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.objEtats.sprints);
                }
                return(null);
            },
        },

        methods: {
            estEnRetard(lUc) {
                return(lUc.enRetard);
            },
            trierCode() {
                if (this.triEnCours == "CODEASC") {
                    this.triEnCours = "CODEDESC";
                }
                else {
                    this.triEnCours = "CODEASC";
                }
                this.ucs.sort(this.triCode);
                this.ucs = JSON.parse(JSON.stringify(this.ucs));
            },
            triCode(x, y) {
                if (this.triEnCours == "CODEASC") {
                    if (x.code < y.code) {
                        return(-1)
                     }
                    if (y.code < x.code) {
                        return(1)
                     }
                }
                else {
                    if (x.code < y.code) {
                        return(1)
                     }
                    if (y.code < x.code) { // tri descendant
                        return(-1)
                     }
                }
            },
            trierHistoriqueEtat() {
                if (this.triEnCours == "ORDREASC") {
                    this.triEnCours = "ORDREDESC";
                }
                else {
                    this.triEnCours = "ORDREASC";
                }
                this.ucs.sort(this.triOrdre);
                this.ucs = JSON.parse(JSON.stringify(this.ucs));
            },
            triOrdre(x, y) {
                if (this.triEnCours == "ORDREDESC") {
                    return(y.historique[0].ordre - x.historique[0].ordre); // tri ascendant
                }
                else {
                    return(x.historique[0].ordre - y.historique[0].ordre); // tri descendant
                }
            },
            trierHistoriqueDate() {
                if (this.triEnCours == "DATEASC") {
                    this.triEnCours = "DATEDESC";
                }
                else {
                    this.triEnCours = "DATEASC";
                }
                this.ucs.sort(this.triDate);
                this.ucs = JSON.parse(JSON.stringify(this.ucs));
            },
            triDate(x, y) {
                if (this.triEnCours == "DATEASC") {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(-1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) {
                        return(1)
                     }
                }
                else {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) { // tri descendant
                        return(-1)
                     }
                }
            },
            modifierUc(laUc) {
                this.$emit('retourChoixUc', laUc);
            },
            editerPDFs(laUc) {
                this.$emit('retourEditerPDFsUc', laUc);
            },
            abandonner() {
                this.confirmDialog = false;
            },
            depublier() {
                this.confirmDialog = false;
                this.depublierUc();
            },
            depublierUc() {
                if (this.laUcCourante) {
                    addeoPost.depublierUc(this.laUcCourante.id).then(response => {
                        this.$emit('retourDepublierUc', null);
                    })
                }
            },
            chargerSprints(laUc) {
                this.$emit('retourChargerSprints', laUc);
            },
            lireUcs() {
                var thisObj = this;
                var ladate=new Date();
                var dateDuJour = ladate.getFullYear()+"-"+(ladate.getMonth()+1)+"-"+ladate.getDate();

                addeoGet.lireUcs(thisObj.leProjetId).then(response => {
                    thisObj.ucsTraiteesSprints = 0;
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.ucs = response.retour.data;

                        // libelle états des historiques
                        thisObj.ucs.forEach((uc, indexProjet) => {
                            var unHistorique = null;
                            uc.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                historique.libelle = lEtat.libelle + " - " + historique.modified.substr(0, 10);
                                historique.libelleEtat = lEtat.libelle;
                                historique.ordre = lEtat.ordre;
                            })
                            thisObj.$parent.analyserRetardUC(uc, dateDuJour, thisObj);
                        })

                    }
                    if (!thisObj.ucs || !thisObj.ucs.length) {
                        thisObj.ready0 = true;
                    }
                })
            },
            modifierHistorique(leHistorique, laUc) {
                leHistorique.codePere = laUc.code;
                this.$emit('retourModifierHistorique', leHistorique);
            }
        }
    }
</script>

<style lang="scss">
    #app {
        #listeUcs {
            .tris {
                .lien {
                   /* text-align: center;*/
                }
            }
            /*
            max-width: 1000px;
            min-width: 400px;
            width: 90%;
            */
            tr, td {
                position:relative;
            }
            tr {
                td {
    /*
                    padding:0.6em 1em;
                    margin:0.4em 1em;

                    &.grand {
                        min-width:200px;
                        width:60%;
                    }

                    &.moyen {
                        min-width:100px;
                        width:30%;
                    }

                    &.historique, &.deadline, &.ico  {
                        vertical-align: top;
                    }
    */
                    .v-select__slot {
                        background-color: transparent;
                        padding-top:0;

                        .v-select__selection {
                            color:#496785;
                        }
                        .v-icon {
                            color:#496785;
                        }
                    }
                    .v-text-field__details {
                        display:none;
                    }

                    .listeHistorique {
                        position:absolute;
                        z-index: 2;
                        left:0;
                        top:0;
                        background-color: #eee;
                        border:#bbb solid 1px;
                        padding:0.4em 2em;
                        width:100%;
                        min-height: 100%;
                        padding:0.4em 1em;
                        box-sizing: border-box;
                        >div {
                            margin-bottom: 0.2em;
                        }
                    }
                }

                &.retard > td {
                    background-color: #fde3b2;
                }
                &.aujourdhui > td {
                    background-color: #ffffc8;
                }
                &.restituer > td {
                    background-color: #d8e4fa;
                }
            }
        }

    }



</style>
