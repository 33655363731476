<template>
    <div id="ucs" class="">
        <div class="">
            <div v-if="ready">
                <span v-if="lesUcs && !lesUcs.length">Pas encore d'UCs dans ce projet !</span>
                <table id="listeUcs" class="tableSTD" v-if="lesUcs && lesUcs.length">
                    <tr class="tris" v-if="vueListe || lesUcs.length == 1">
                        <th></th>
                    </tr>
                    <tr class="tris" v-if="!vueListe && lesUcs.length > 1">
                        <th  class="lien colspan5"  @click.stop="trierCode">
                            <span>Libellé</span>
                            <i v-if="triEnCours != 'CODEDESC' && triEnCours != 'CODEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'CODEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueEtat">
                            <span>Etat</span>
                            <i v-if="triEnCours != 'ORDREDESC' && triEnCours != 'ORDREASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'ORDREASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="lien" @click.stop="trierHistoriqueDate">
                            <span>Début</span>
                            <i v-if="triEnCours != 'DATEDESC' && triEnCours != 'DATEASC'" class="fa fa-sort" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEDESC'" class="fa fa-caret-down" aria-hidden="true"></i>
                            <i v-if="triEnCours == 'DATEASC'" class="fa fa-caret-up" aria-hidden="true"></i>
                        </th>
                        <th class="ico" ></th>
                    </tr>
                    <tr class="itemUc"  v-for="(laUc, numUc) in lesUcs" :id="numUc" :key="numUc" v-bind:class="{seul : vueListe == laUc.id, pair: Math.trunc(numUc/2)*2==numUc}"  >

                        <td colspan="10" class="colspanGroup colspanGroup10" v-if="1 || (!vueListe || (vueListe == laUc.id))">

                            <table class="tableSTD" >
                                <tr>
                                    <td v-if="vueListe != laUc.id" class="ico">
                                        <span @click.stop="chargerSprints(laUc)" class="lien" title="Ouvrir la liste des sprints de l'UC">
                                            <i class="fa fa-plus svg zoneClient" aria-hidden="true"></i>
                                        </span>
                                    </td>
                                    <td v-if="vueListe == laUc.id" class="ico">
                                        <span @click.stop="chargerSprints(laUc)" class="lien" title="Refermer la liste des sprints de l'UC">
                                            <i  class="fa fa-minus svg zoneClient" aria-hidden="true"></i>
                                        </span>
                                    </td>

                                    <td v-if="vueInfos != laUc.id" class="libelle grand" >
                                        {{laUc.code}} - {{laUc.libelle}}
                                        <span @click.stop="voirInfos(laUc)" class="lien noFloat zoneClient" title="Obtenir la description et la documentation de l'UC">
                                            &nbsp;-&nbsp;
                                            <span class="noFloat ">
                                                <span class="noFloat noRC">
                                                    (voir)
                                                    <span class="noFloat" v-if="laUc.nbDocs">
                                                        {{laUc.nbDocs}}&nbsp;<i class="fa fa-file svg petit" aria-hidden="true"></i>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                    <td v-if="vueInfos == laUc.id" class="libelle grand">
                                        {{laUc.code}} - {{laUc.libelle}}
                                        <span @click.stop="voirInfos(laUc)" title="Refermer la description et la documentation de l'UC" class="lien noFloat zoneClient">
                                            &nbsp;-&nbsp;
                                            <span class="noFloat">(refermer)</span>
                                        </span>
                                    </td>

                                    <td colspan="2" class="historique" @mouseover="numActiveCourant = numUc" @mouseleave="numActiveCourant = -1">
                                        <div class="historiqueGauche">
                                            <span>
                                                {{laUc.historique[0].libelleEtat}}
                                            </span>
                                        </div>
                                        <div class="historiqueDroite">
                                            au
                                            <span>
                                                {{laUc.historique[0].dateAffichee}}
                                            </span>
                                        </div>
                                        <div class="listeHistorique" v-if="numActiveCourant == numUc"  >

                                            <ul>
                                                <li class="itemHistorique" v-for="(leHistorique, numHistorique) in laUc.historique">
                                                <div class="historiqueGauche">
                                                    <span>
                                                        {{leHistorique.libelleEtat}}
                                                    </span>
                                                </div>
                                                <div class="historiqueDroite">
                                                    au
                                                    <span>
                                                        {{leHistorique.dateAffichee}}
                                                    </span>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>

                                    <td class="centrer nbItems">
                                        <div v-if="!laUc.nbSprints">
                                            &nbsp;
                                        </div>
                                        <div v-if="laUc.nbSprints">
                                            <span  class=" ico centrer plusPetit">{{laUc.nbSprints}}&nbsp;Sprints</span>
                                        </div>
                                    </td>

                                </tr>

<transition name="slide-fade" >
                                <tr v-if="leContexte.uc && (vueInfos == laUc.id)" class="infosDocs">
                                    <td colspan="5" class="colspanGroup">
                                        <InfosDocs :leContexte="leContexteUc"  />
                                    </td>
                                </tr>
</transition>

<transition name="slide-fade" >
                                <tr v-if="leContexte.uc && (vueListe == laUc.id)">
                                    <td colspan="5" class="colspanGroup">
                                        <Sprints :leContexte="leContexte"  />
                                    </td>
                                </tr>
</transition>

                            </table>

                        </td>

                    </tr>
                </table>

            </div>

        </div>

    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
    import Sprints from '@/components/front/FSprints.vue'
    import InfosDocs from '@/components/front/InfosDocs.vue'


    export default {
        name: 'ucs',
        components: {
            Sprints,
            InfosDocs,
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                leProjetId:this.leContexte.projet.id,
                ucs:null,
                ready:false,
                //tabHistoriquesModels:[],
                numActiveCourant:-1,
                triEnCours:"",
                confirmDialog:false,
                laUcCourante:null,
                vueInfos:false,
                vueListe:false
            }
        },


        watch: {
            leContexte(newValue) {
                if (newValue) {
                    this.leProjetId = this.leContexte.projet.id,
                    this.lireUcs();
                }
            },
        },

        created: function () {
            if (this.leProjetId) {
                this.lireUcs();
            }

        },

        mounted: function () {
        },

        computed: {
            lesUcs() {
                return(this.ucs);
            },
            lesEtats () {
                if (this.$parent.$parent.$parent.objEtats) {
                    return(this.$parent.$parent.$parent.objEtats.ucs);
                }
                return(null);
            },
            leContexteUc() {
                var contexteUc = JSON.parse(JSON.stringify(this.leContexte));
                contexteUc.sprint = null;
                return(contexteUc)
            }
        },

        methods: {
            trierCode() {
                if (this.triEnCours == "CODEASC") {
                    this.triEnCours = "CODEDESC";
                }
                else {
                    this.triEnCours = "CODEASC";
                }
                this.ucs.sort(this.triCode);
                this.ucs = JSON.parse(JSON.stringify(this.ucs));
            },
            triCode(x, y) {
                if (this.triEnCours == "CODEASC") {
                    if (x.code < y.code) {
                        return(-1)
                     }
                    if (y.code < x.code) {
                        return(1)
                     }
                }
                else {
                    if (x.code < y.code) {
                        return(1)
                     }
                    if (y.code < x.code) { // tri descendant
                        return(-1)
                     }
                }
            },
            trierHistoriqueEtat() {
                if (this.triEnCours == "ORDREASC") {
                    this.triEnCours = "ORDREDESC";
                }
                else {
                    this.triEnCours = "ORDREASC";
                }
                this.ucs.sort(this.triOrdre);
                this.ucs = JSON.parse(JSON.stringify(this.ucs));
            },
            triOrdre(x, y) {
                if (this.triEnCours == "ORDREDESC") {
                    return(y.historique[0].ordre - x.historique[0].ordre); // tri ascendant
                }
                else {
                    return(x.historique[0].ordre - y.historique[0].ordre); // tri descendant
                }
            },
            trierHistoriqueDate() {
                if (this.triEnCours == "DATEASC") {
                    this.triEnCours = "DATEDESC";
                }
                else {
                    this.triEnCours = "DATEASC";
                }
                this.ucs.sort(this.triDate);
                this.ucs = JSON.parse(JSON.stringify(this.ucs));
            },
            triDate(x, y) {
                if (this.triEnCours == "DATEASC") {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(-1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) {
                        return(1)
                     }
                }
                else {
                    if (x.historique[0].modified < y.historique[0].modified) {
                        return(1)
                     }
                    if (y.historique[0].modified < x.historique[0].modified) { // tri descendant
                        return(-1)
                     }
                }
            },

            voirInfos(laUc) {
                if (this.vueInfos == laUc.id) {
                    this.vueInfos = false;
                }
                else {
                    this.vueListe = false;
                    this.vueInfos = laUc.id;
                    this.leContexte.uc = laUc;
                }
            },

            chargerSprints(laUc) {
                if (this.vueListe == laUc.id) {
                    this.vueListe = false;
                }
                else {
                    this.vueInfos = false;
                    this.vueListe = laUc.id;
                    this.leContexte.uc = laUc;
                }
            },
            lireUcs() {
                var thisObj = this;
                addeoGet.lireUcs(thisObj.leProjetId).then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.ucs = response.retour.data;

                        // libelle états des historiques
                        thisObj.ucs.forEach((uc, indexProjet) => {
                            var unHistorique = null;
                            uc.historique.forEach((historique, index) => {
                                const lEtat = thisObj.lesEtats[historique.const_etat_code];
                                historique.libelle = lEtat.libelle + " (" + historique.modified.substr(0, 10) +")";
                                historique.libelleEtat = lEtat.libelle;
                                historique.libelleDate = historique.modified.substr(0, 10);
                                historique.dateAffichee = this.traiterDate(historique.libelleDate);
                                historique.ordre = lEtat.ordre;
                            })
                        })

                        this.triEnCours = "";
                        this.trierCode();
                        thisObj.ready = true;
                    }
                })
            },
            traiterDate(datePasAffichee) {
                var tab = datePasAffichee.split('-')
                var dateAffichee = tab[2]+"-"+tab[1]+"-"+tab[0];
                return(dateAffichee);

            },
        }
    }
</script>

<style lang="scss">
    #app {
        #front {
            #listeProjets {
                #listeUcs {
                    margin-top:1em;
                    font-size:95%;

                    .tris {
                        th {
                            &.colspan5 {
                                /*width:54%;*/
                                width:66%;
                            }
                            &.colspan2 {
                            }
                        }
                    }


                    tr, td {
                        position:relative;
                    }
                    td {

                        &.historique {
                            /*vertical-align: top;*/
                        }

                        .v-select__slot {
                            background-color: transparent;
                            padding-top:0;

                            .v-select__selection {
                                color:#496785;
                            }
                            .v-icon {
                                color:#496785;
                            }
                        }
                        .v-text-field__details {
                            display:none;
                        }

                        .listeHistorique {
                            position:absolute;
                            z-index: 2;
                            left:0;
                            top:0;
                            background-color: #eee;
                            border:#bbb solid 1px;
                            padding:0.4em 2em;
                            width:100%;
                            min-height: 100%;
                            padding:0.4em 1em;
                            box-sizing: border-box;
                            >div {
                                margin-bottom: 0.2em;
                            }
                        }
                    }
                }
            }
        }

    }



</style>
