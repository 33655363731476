<template>
    <div id="editerProjet" class="">
        <div v-if="!nouveauProjet" class="sousTitre">
            <h4 class="aGauche">
                Projets
            </h4>
            <div class="aDroite">
                <v-btn  class="a-btn prioritaire" type="button" @click="nouveauProjet=true"><i class="fa fa-plus svg" aria-hidden="true"></i>&nbsp;{{libelleCheckBox}}</v-btn>
            </div>
            <div class="clear"></div>
        </div>
<!--
        <span class="checkbox">
            <v-checkbox
                v-model="nouveauProjet"
                :label="libelleCheckBox"
                on-icon="fa fa-check-square"
                off-icon="fa fa-square"
            ></v-checkbox>
        </span>
-->
        <div v-if="nouveauProjet">
                <div class="edition">
                    <p class="info">Les champs suivis d'une * sont obligatoires.</p>
                    <v-form v-if="ready">
                        <v-text-field
                                v-model="form.code"
                                :error-messages="error.code"
                                label="Code du projet : *"
                                required
                                @input="$v.form.code.$touch()"
                                @blur="$v.form.code.$touch()"
                                ref="code"
                        ></v-text-field>
                        <v-text-field
                                v-model="form.libelle"
                                :error-messages="error.libelle"
                                label="Nom du projet : *"
                                required
                                @input="$v.form.libelle.$touch()"
                                @blur="$v.form.libelle.$touch()"
                                ref="libelle"
                        ></v-text-field>

                        <v-textarea
                                v-model="form.remarques"
                                label="Remarques / description : "
                        ></v-textarea>

                        <v-select
                            dense
                            v-model="form.etatCode"
                            item-text="libelle"
                            item-value="code"
                            :items="lesEtats"
                            label="Indiquer l'état du projet"
                            required
                            ref="etatCode"
                        ></v-select>


                        <div class="boutonsValidation">
                            <v-btn   class="a-btn prioritaire" type="button" @click="demanderMaj">Valider</v-btn>
                            <v-btn   class="a-btn " type="button" @click="demanderAbandonnerMaj">Abandonner</v-btn>
                        </div>

                    </v-form>


                </div>



            <v-dialog v-model="dialogError" v-if="errorDemandeMaj" @click:outside="fermerDialogError()" >
                 <v-card>
                     <v-card-title class="headline">Enregistrement du projet</v-card-title>
                     <v-card-text>
                         <span v-if="errorDemandeMaj">{{errorDemandeMaj}}</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="confirmDialog">
                    <v-card>
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous abandonner votre saisie d'un projet ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="reprendreSaisie">Non</v-btn>
                            <v-btn color="" text @click="abandonnerSaisie">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
        </div>

    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
/*
    import { ApiTools } from "../../js/api/index.js"
*/
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'


    export default {
        mixins: [validationMixin],
        name: 'projetForm',
        components: {
        },
        validations: {
            form: {
                code: { required, maxLength: maxLength(28)},
                libelle: { required, maxLength: maxLength(256) },
                remarques: {   },
                etatCode: {  required },
            }
        },
        props: {
        /**
         * Données à injecter
         */
            leProjet: Object,
            familleId: Number,
        },

        data() {
            return {
                nouveauProjet:false,
                form: {
                    id:0,
                    code: "",
                    libelle: "",
                    remarques:"",
                    famille_id:0,
                    publie:0,
                    archive:0,
                    etatCode:""
                },
                old: null,
                error: {
                    code: "",
                    libelle: "",
                    remarques:"",
                    etatCode:"",
                },
                confirmDialog:false,
                dialogError:false,
                errorDemandeMaj:false,
                messageMaj:"",
                messageSucces:"Le projet est enregistré !",
                ready:false,
            }
        },


        watch: {
            leProjet(newValue) {
                if (newValue) {
                    this.form.id = this.leProjet.id;
                    this.form.code = this.leProjet.code;
                    this.form.libelle = this.leProjet.libelle;
                    this.form.remarques = this.leProjet.remarques;
                    if (this.leProjet.historique && this.leProjet.historique.length) {
                        this.form.etatCode = this.leProjet.historique[0].const_etat_code;
                    }
                    this.form.famille_id = this.leProjet.famille_id;
                    this.form.publie = this.leProjet.publie;
                    this.form.archive = this.leProjet.archive;
                    this.old = JSON.parse(JSON.stringify(this.form));
                    this.nouveauProjet = true;
                    // etatCode
                }
            },
            nouveauProjet(newValue) {
                if (!newValue) {
                    this.initForm();
                    this.$emit('editionEnCours', false);
                }
                else {
                    this.$emit('editionEnCours', true);
                }
            },
            ready(newValue) {
                if (newValue) {
                    this.initForm();
                }
            }
        },

        created: function () {
            if (this.lesEtats) {
                this.ready = true;
            }

        },

        mounted: function () {
        },

        computed: {
            libelleCheckBox() {
                if (this.form.id) {
                    return('Modification de "' +this.form.libelle+'"');
                }
                return("Nouveau projet");
            },
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    var info = new Object();
                    info.libelle = "Indiquez un état";
                    info.code = "";
                    var tab = this.$parent.$parent.objEtats.projets.slice();
                    tab.unshift(info);
                    return(tab);
                }
                return(null);
            },
            codeErrors () {
                if (!this.$v.form.code.$invalid) return false // verifier invalid
                if (!this.$v.form.code.required) {
                     this.error.code  = "Vous devez saisir un code unique pour le projet"
                }
                this.$refs.code.focus();
                return true
            },
            libelleErrors () {
                if (!this.$v.form.libelle.$invalid) return false // verifier invalid
                if (!this.$v.form.libelle.required) {
                     this.error.libelle  = "Vous devez saisir un libellé"
                }
                this.$refs.libelle.focus();
                return true
            },
            remarquesErrors () {
                if (!this.$v.form.remarques.$invalid) return false // verifier invalid
                /*
                if (!this.$v.form.remarques.required) {
                     this.error.remarques  = "Vous devez saisir votre remarques"
                }
                */
                this.$refs.remarques.focus();
                return true
            },
            etatCodeErrors () {
                if (!this.$v.form.etatCode.$invalid) return false // verifier invalid
                if (!this.$v.form.etatCode.required) {
                     this.error.etatCode  = "Vous devez indiquer un état"
                }
                this.$refs.etatCode.focus();
                return true
            },

        },
        methods: {
            initForm() {
                this.form.id = 0;
                this.form.code = "";
                this.form.libelle = "";
                this.form.remarques = "";
                this.form.etatCode = this.lesEtats[1].const_etat_code;
                this.form.famille_id = this.familleId;
                this.form.publie = 1;
                this.form.archive = 0;
            },
            reinitErrorMessages() {
            },
            abandonnerSaisie() {
                this.confirmDialog=false;
                this.nouveauProjet=false;
                //this.$emit('retourProjetPdf', null);
            },
            reprendreSaisie() {
                this.confirmDialog=false;
            },
            demanderAbandonnerMaj() {
                this.confirmDialog=true;
            },
            demanderMaj() {
                this.reinitErrorMessages();
                this.$v.$touch();
                var codeErrors = this.codeErrors;
                var libelleErrors = this.libelleErrors;
                var remarquesErrors = this.remarquesErrors;
                var etatCodeErrors = this.etatCodeErrors;
                if ( (this.$v.$invalid &&
                      (codeErrors || libelleErrors || remarquesErrors || etatCodeErrors )) ) {

                    console.log("submit pas OK");
                    return;
                }

                // si pas de modification des données
                if (this.old && (JSON.stringify(this.old) === JSON.stringify(this.form))) {
                    this.errorDemandeMaj = "Le projet est inchangé, inutile de l'enregistrer !";
                    this.dialogError = true;
                    return
                }

                var thisObj = this;
                addeoPost.majProjet(this.form)
                .then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        //this.X = response.retour.data;

                        /*
                        thisObj.messageMaj = "Mise à jour du projet OK !";
                        thisObj.$emit('retourMaj', thisObj.messageMaj);
                        */

                        thisObj.errorDemandeMaj = thisObj.messageSucces;
                        thisObj.dialogError = true;
                        thisObj.$emit('retourProjet', response.retour.data);
                    }
                    else {
                        this.dialogError = true;
                        var complementErreur = "merci de nous contacter";
                        if (response && response.erreur) {
                            complementErreur = response.erreur;
                        }
                        this.errorDemandeMaj = "Enregistrement impossible : " + complementErreur;
                        console.log("demanderMaj non valide")
                    }
                })
            },

            fermerDialogError(data) {
                this.dialogError = false;

                if (this.errorDemandeMaj) {
                    if (this.errorDemandeMaj === this.messageSucces) {
                        this.nouveauProjet = false;
                        this.initForm();
                    }
                    else {
                        this.$nextTick(() => {
                            this.$refs.code.focus();
                        })
                    }
                }
                this.errorDemandeMaj = false;
            },
            // pour la saisie
            /*
            filtrerNombresEntiersPositifs(e){
                return(ApiTools.filtrerNombresEntiersPositifs(e))
            },
            */
        }
    }
</script>

<style lang="scss">
    #app {
        #editerProjet {
            .edition {
                margin:1em 0 0 4em;
            }
        }
    }



</style>
