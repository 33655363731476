<template>
    <div id="infosDocs" class="contenu">
        <div class="">
            <div v-if="nouveauContexte && ready">
                <div class="infos" v-if="infos!=''">
                    {{lesInfos}}
                </div>
                <span v-if="lesPdfs && !lesPdfs.length">Pas encore de documents ou de liens !</span>
                <table id="listePdfs" class="tableSTD" v-if="lesPdfs && lesPdfs.length">
                    <tr class="itemPdf"  v-for="(lePdf, numPdf) in lesPdfs" :id="numPdf" :key="numPdf" v-bind:class="{ pair: Math.trunc(numPdf/2)*2==numPdf}"  >
                        <!-- <td v-if="lePdf.fichier" @click.stop="ouvrirPdf(lePdf)" class="libelle auto lien"> -->
                        <td v-if="lePdf.fichier" @click.stop="ouvrirPdf(lePdf)" class="libelle lien">
                            <i class="fa fa-file svg" aria-hidden="true"></i>{{lePdf.titre}}
                            <span class="petit description" v-if="lePdf.description"><br/>{{lePdf.description}}</span>
                        </td>
                        <!-- <td v-if="lePdf.lien" class="libelle auto lien"> -->
                        <td v-if="lePdf.lien" class="libelle lien">
                            <i class="fa fa-link svg" aria-hidden="true"></i>
                            <a :href="preparerLien(lePdf)" target="_blank">{{lePdf.titre}}
                            <span class="petit description" v-if="lePdf.description"><br/>{{lePdf.description}}</span>
                            </a>
                        </td>
                        <!--
                        <td class="fin">
                            &nbsp;
                        </td>
                        -->
                    </tr>
                </table>

            </div>

        </div>

    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"


    export default {
        name: 'pdfs',
        components: {
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,

        },

        data() {
            return {
                infos:"",
                leProjetId:0,
                laUcId:0,
                leSprintId:0,
                pdfs:null,
                ready:false,
                recharger:false
            }
        },


        watch: {
            recharger(newValue) {
                if (newValue) {
                    this.leProjetId = this.leContexte.projet.id;
                    this.infos = this.leContexte.projet.remarques;
                    if (this.leContexte.uc) {
                        this.infos = this.leContexte.uc.remarques;
                        this.laUcId = this.leContexte.uc.id;
                    }
                    if (this.leContexte.sprint) {
                        this.infos = this.leContexte.sprint.remarques;
                        this.leSprintId = this.leContexte.sprint.id;
                    }
                    if (this.leProjetId || this.laUcId || this.leSprintId) {
                        this.lirePdfs();
                    }
                }
            }

        },

        created: function () {

        },

        mounted: function () {
        },

        computed: {
            lesInfos() {
                return("Informations : "+this.infos);
            },
            nouveauContexte() {
                this.recharger = true;
                return(this.leContexte);
            },
            lesPdfs() {
                return(this.pdfs);
            },
        },

        methods: {
            preparerLien(lePdf) {
                return(lePdf.lien.startsWith("http") ? lePdf.lien : "http://"+lePdf.lien);
                },
            ouvrirPdf(lePdf) {
                addeoGet.downloadDocument(lePdf).then(blob => {
                    if (blob) {
                        //const url = window.URL.createObjectURL(new Blob([response]));
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', lePdf.fichier); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                    else {
                        console.log("pb ouvrirPdf");
                    }
                })
            },
            lirePdfs() {
                var thisObj = this;
                addeoGet.lireDocuments(thisObj.leProjetId, thisObj.laUcId, thisObj.leSprintId).then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.pdfs = response.retour.data;
                        thisObj.ready = true;
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    #app {

        #front {
            .infos {
                margin:0 0 2em;
            }
            #listePdfs {
                max-width: 1000px;
                min-width: 400px;
                width: 90%;
                tr, td {
                    position:relative;

                    &.itemPdf {
                        .fa {
                            margin-right: 1em;
                        }
                    }
                }
                td {
                    padding:0.6em 1em;
                    margin:0.4em 1em;

                    &.auto {
                        white-space: nowrap;
                    }

                    &.ico {
                        width:1%;
                        max-width:30px;
                        /*
                        min-width:50px;
                        width:15%;
                        */
                    }
                    &.fin {
                        width:100%;
                    }
    /*
                    .v-select__slot {
                        background-color: transparent;
                        padding-top:0;

                        .v-select__selection {
                            color:#496785;
                        }
                        .v-icon {
                            color:#496785;
                        }
                    }
                    .v-text-field__details {
                        display:none;
                    }
    */
                    .description {
                        opacity:0.7;
                        float:none;
                    }
                }
            }
        }

    }



</style>
