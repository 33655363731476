<template>
    <div id="containerEditerHistorique" class="contenu">

        <div v-if="ready" id="editerHistorique">
            <h2 class="warning">Correction de l'historique d'état de {{codePere}}</h2>
                <div class="edition">
<!--
                    <p class="info">Les champs suivis d'une * sont obligatoires.</p>
-->
                    <v-form>
<!--
                        <div class="champInactif">
                            {{infosCodeLibelle}}
                        </div>
-->
                        <v-select
                            dense
                            v-model="form.const_etat_code"
                            item-text="libelle"
                            item-value="code"
                            :items="lesEtats"
                            label="Indiquer l'état du projet"
                            required
                            ref="etatCode"
                        ></v-select>


                        <v-menu
                            v-model="lePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="form.modified"
                                    label="Date associée"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ref="deadline"
                                  >
                                </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="form.modified"
                              @input="lePicker = false"
                            locale="fr"
                            >
                            </v-date-picker>
                        </v-menu>

                        <div class="boutonsValidation">
                            <v-btn   class="a-btn prioritaire" type="button" @click="demanderMaj">Valider</v-btn>
                            <v-btn   class="a-btn " type="button" @click="demanderAbandonnerMaj">Abandonner</v-btn>
                        </div>

                    </v-form>


            <v-dialog v-model="dialogError" v-if="errorDemandeMaj" @click:outside="fermerDialogError()" >
                 <v-card>
                     <v-card-title class="headline">Enregistrement de l'historique</v-card-title>
                     <v-card-text>
                         <span v-if="errorDemandeMaj">{{errorDemandeMaj}}</span>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="" text @click="fermerDialogError()">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="confirmDialog">
                    <v-card>
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>
                            <div>Voulez-vous abandonner votre correction de l'historique ?</div>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="" text @click="reprendreSaisie">Non</v-btn>
                            <v-btn color="" text @click="abandonnerSaisie">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>

                </div>


        </div>

    </div>
</template>


<script>

    import { addeoPost, addeoGet } from "../../js/datas.js"
/*
    import { ApiTools } from "../../js/api/index.js"
*/
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, minLength } from 'vuelidate/lib/validators'

    //import MenuIcon from 'vue-material-design-icons/Menu.vue';

    //import 'vue-material-design-icons/styles.css';

    export default {
        mixins: [validationMixin],
        name: 'historiqueForm',
        components: {
            //MenuIcon
        },
        validations: {
            form: {
                modified: {  required },
            }
        },
        props: {
        /**
         * Données à injecter
         */
            leContexte: Object,
        },

        data() {
            return {
                leHistorique:this.leContexte.historique,
                form: {
                    id:0,
                    const_etat_code: "",
                    projet_id: "",
                    uc_id:0,
                    sprint_id:"",
                    modified:"",
                    visibilite:false
                },
                old: null,
                error: {
                    modified: "",
                },
                confirmDialog:false,
                dialogError:false,
                errorDemandeMaj:false,
                messageMaj:"",
                messageSucces:"L'historique est enregistré !",
                ready:false,
                lePicker: false,
                nouvelHistorique:null
            }
        },


        watch: {
            nouvelHistorique(newValue) {
                if (newValue) {
                    this.form.id = this.leHistorique.id;
                    this.form.const_etat_code = this.leHistorique.const_etat_code;
                    this.form.projet_id = this.leHistorique.projet_id;
                    this.form.uc_id = this.leHistorique.uc_id;
                    this.form.sprint_id = this.leHistorique.sprint_id;
                    this.form.visibilite = this.leHistorique.visibilite;
                    this.form.modified = this.leHistorique.modified.slice(0,10);
                    this.old = JSON.parse(JSON.stringify(this.form));
                    this.ready = true;
                }
            },
        },

        created: function () {
            this.nouvelHistorique = null;
        },

        mounted: function () {
            this.nouvelHistorique = this.leHistorique;
        },

        computed: {
            lesEtats () {
                if (this.$parent.$parent.objEtats) {
                    /*
                    var info = new Object();
                    info.libelle = "Indiquez un état";
                    info.code = "";
                    */
                    if (this.form.sprint_id) {
                        var tab = this.$parent.$parent.objEtats.sprints.slice();
                    }
                    else {
                        if (this.form.uc_id) {
                            var tab = this.$parent.$parent.objEtats.ucs.slice();
                            }
                        else {
                            var tab = this.$parent.$parent.objEtats.projets.slice();
                            }
                        }
                    //tab.unshift(info);
                    return(tab);
                }
                return(null);
            },
            codePere() {
                if (this.leHistorique.codePere)   {
                    return(this.leHistorique.codePere);
                }
                return("");
            },
            infosCodeLibelle() {
                return(/*this.form.const_etat_code+" - "+*/this.leHistorique.libelleEtat);
            },
            modifiedErrors () {
                if (!this.$v.form.modified.$invalid) return false // verifier invalid
                if (!this.$v.form.modified.required) {
                     this.error.modified  = "Vous devez indiquer une date"
                }
                this.$refs.modified.focus();
                return true
            },

        },
        methods: {
            initForm() {
                /*
                this.form.id = 0;
                this.form.const_etat_code = "";
                this.form.projet_id = 0;
                this.form.uc_id = 0;
                this.form.sprint_id = 0;
                this.form.modified = 0;
                */
                this.old = JSON.parse(JSON.stringify(this.form));
            },
            reinitErrorMessages() {
            },
            sortir() {
                this.ready = false;
                this.nouvelHistorique = null;
                this.$emit('retourHistorique', null);
            },
            abandonnerSaisie() {
                this.confirmDialog=false;
                this.sortir();
            },
            reprendreSaisie() {
                this.confirmDialog=false;
            },
            demanderAbandonnerMaj() {
                this.confirmDialog=true;
            },
            demanderMaj() {
                this.reinitErrorMessages();
                this.$v.$touch();
                var modifiedErrors = this.modifiedErrors;
                if (this.$v.$invalid && modifiedErrors) {

                    console.log("submit pas OK");
                    return;
                }

                // si pas de modification des données
                if (this.old && (JSON.stringify(this.old) === JSON.stringify(this.form))) {
                    this.errorDemandeMaj = "L'historique est inchangé, inutile de l'enregistrer !";
                    this.dialogError = true;
                    return
                }

                var thisObj = this;
                addeoPost.majHistorique(this.form)
                .then(response => {
                    if (response && response.retour && response.retour.success == true) {
                        thisObj.errorDemandeMaj = thisObj.messageSucces;
                        thisObj.dialogError = true;
                        this.sortir();
                    }
                    else {
                        this.dialogError = true;
                        var complementErreur = "merci de nous contacter";
                        if (response && response.erreur) {
                            complementErreur = response.erreur;
                        }
                        this.errorDemandeMaj = "Enregistrement impossible : " + complementErreur;
                        console.log("demanderMaj non valide")
                    }
                })
            },

            fermerDialogError(data) {
                this.dialogError = false;

                if (this.errorDemandeMaj) {
                    if (this.errorDemandeMaj === this.messageSucces) {
                        this.initForm();
                    }
                    else {
                        this.$nextTick(() => {
                            this.$refs.modified.focus();
                        })
                    }
                }
                this.errorDemandeMaj = false;
            },

        }
    }
</script>

<style lang="scss">
    #app {
        #containerEditerHistorique {
            position:absolute;
            top:0;
            /*left:0;*/
            width:100%;
            height:100%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;

            #editerHistorique {
                text-align: left;
                max-width: 800px;
                width:90%;
                position:relative;
                z-index:100;
                background-color: #dddddd;
                margin:0 auto;
                .edition {
                    padding:1em 2em;
                }
                h2.warning {
                    color:orange;
                }
            }

            .v-picker__title.primary {
                background-color: #1867c0 !important;
                border-color: #1867c0 !important;
                max-height:10px;
                div {
                    display:none ;
                }
            }
            .v-date-picker-title {
                color:white;
            }
        }
    }



</style>
