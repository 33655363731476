<template>
  <div class="home">
    <img alt="Vue logo" src="../../public/img/grand-est.png">
    <Portefeuille msg="Bienvenue sur votre portefeuille de projets"/>
  </div>
</template>

<script>
// @ is an alias to /src
//import Portefeuille from '@/components/Portefeuille.vue'

export default {
  name: 'Home',
  components: {
    //Portefeuille
  }
}
</script>
