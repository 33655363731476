class ApiUploadObj {

    upload(formData) {
        const lePDF = formData.getAll('lePDF');
        const promises = lePDF.map((x) => this.getPDF(x)
            .then(pdf => ({
                pdf: pdf,
                name:lePDF[0].name
            })));
        return Promise.all(promises);
    }
    getPDF(file) {
        return new Promise((resolve, reject) => {
            const fReader = new FileReader();
            fReader.onload = function (e) {
                const buffer = e.target.result;
                const u8Array = new Uint8Array(buffer);
                const retour = new Blob([u8Array]);
                resolve(retour);
                }

            fReader.readAsArrayBuffer(file);
            /*
            const fReader = new FileReader();
            fReader.onload = function (e) {
                const buffer = e.target.result;
                const retour = buffer.split('base64,')[1];
                resolve(retour);
                }

            fReader.readAsDataURL(file);
            */
            /*
            const fReader = new FileReader();
            fReader.onload = function (e) {
                const buffer = e.target.result;
                const retour = buffer;
                resolve(retour);
                }

            fReader.readAsArrayBuffer(file);
            */
            //fReader.readAsText(file, "iso-8859-1");
            //fReader.readAsBinaryString(file);
        })
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
           const reader = new FileReader();

            reader.onload = function (e) {
                resolve(e.target.result);
            }
           reader.readAsDataURL(file);
        })
    }

    /*

    getCSV(file) {
        return new Promise((resolve, reject) => {
            const fReader = new FileReader();
            fReader.onload = function (e) {
                resolve(e.target.result);
            }

            fReader.readAsText(file);
        })
    }

    getImage(file) {
        return new Promise((resolve, reject) => {
            const fReader = new FileReader();
            const img = document.createElement('img');

            fReader.onload = () => {
                img.src = fReader.result;
                resolve(this.getBase64Image(img));
            }

            fReader.readAsDataURL(file);
        })
    }

    getBase64Image(img) {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        return dataURL;
    }
    */
}

export const ApiUpload = new ApiUploadObj()
